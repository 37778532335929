export default [
  {
    id: 'joy',
    shortcode: 'joy',
  },
  {
    id: 'wink',
    shortcode: 'wink',
  },
  {
    id: 'blush',
    shortcode: 'blush',
  },
  {
    id: 'sunglasses',
    shortcode: 'sunglasses',
  },
  {
    id: 'heart_eyes',
    shortcode: 'heart_eyes',
  },
  {
    id: 'kissing_heart',
    shortcode: 'kissing_heart',
  },
  {
    id: 'thinking_face',
    shortcode: 'thinking_face',
  },
  {
    id: 'smirk',
    shortcode: 'smirk',
  },
  {
    id: 'cry',
    shortcode: 'cry',
  },
  {
    id: 'sob',
    shortcode: 'sob',
  },
  {
    id: 'hankey',
    shortcode: 'poop',
  },
  {
    id: 'see_no_evil',
    shortcode: 'see_no_evil',
  },
  {
    id: 'v',
    shortcode: 'v',
  },
  {
    id: 'ok_hand',
    shortcode: 'ok_hand',
  },
  {
    id: '+1',
    shortcode: '+1',
  },
  {
    id: 'heart',
    shortcode: 'heart',
  },
  {
    id: 'eyes',
    shortcode: 'eyes',
  },
  {
    id: 'tada',
    shortcode: 'tada',
  },
  {
    id: 'fire',
    shortcode: 'fire',
  },
  {
    id: '100',
    shortcode: '100',
  },
];
