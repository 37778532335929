import React, { Component } from 'react';
import withSizes from 'react-sizes';
import { FixedSizeGrid as Grid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import EmojiPicker from './../EmojiPicker';
import EmojiItem from './../EmojiItem';

import './styles.scss';


class EditEmojis extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      pickerVisible: false,
      gridRef: React.createRef(),
    };

    this.setPickerVisibility = this.setPickerVisibility.bind(this);
    this.addEmoji = this.addEmoji.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state !== nextState
      || this.props.base !== nextProps.base
      || this.props.skin !== nextProps.skin
      || this.props.baseEmojis.length !== nextProps.baseEmojis.length
      || this.props.isGtTablet !== nextProps.isGtTablet
    );
  }

  setPickerVisibility(bool) {
    this.setState({pickerVisible: bool}, () => {
      this.state.gridRef.current.scrollToItem({
        align: 'end',
        rowIndex: Number.MAX_SAFE_INTEGER,
      });
    });
  }

  addEmoji(emoji) {
    this.setPickerVisibility(false);
    this.props.handleNewEmojiShortcode(emoji.id, emoji.skin);
  }

  render() {
    const COLUMN_COUNT = this.props.isGtTablet ? 4 : 1;
    const MAX_ROWS = 7;
    const ROW_HEIGHT = 55;
    const ROW_COUNT = Math.ceil((this.props.baseEmojis.length + 1) / COLUMN_COUNT);

    const Cell = ({ columnIndex, rowIndex, style }) => {
      const emojiIndex = (rowIndex * COLUMN_COUNT) + columnIndex;

      // Empty cells at the end of the grid
      let contents = (<div></div>);

      // The add button at the end of the base emojis
      if (emojiIndex === this.props.baseEmojis.length) {
        contents = (
          <div>
            <button className="AddEmoji button is-primary" onClick={() => this.setPickerVisibility(true)}>
              <span className="icon">
                <i className="fas fa-plus"></i>
              </span>
              <span>Add</span>
            </button>
          </div>
        );
      }

      // Else get the emoji at the index
      else if (emojiIndex < this.props.baseEmojis.length) {
        const emoji = this.props.baseEmojis[emojiIndex];
        contents = (
          <EmojiItem
            key={emojiIndex}
            index={emojiIndex}
            id={emoji.id}
            skin={emoji.skin}
            shortcode={emoji.shortcode}
            handleEmojiShortcodeChange={this.props.handleEmojiShortcodeChange}
          />
        );
      }

      // Add the gutters and return the surrounding div with contents
      return (
        <div style={style}>
          {contents}
        </div>
      );
    };

    return (
      <div className="EditEmojis card">
        <header id="add-header" className="card-header has-text-white" data-action="collapse" data-target="add-card">
          <h3 className="card-header-title has-text-white"><span role="img" aria-label="Edit">✍️</span>&nbsp;&nbsp;Edit Emojis</h3>
          <p className="card-header-icon" aria-label="more options">
            <span className="icon">
              <i className="fas fa-angle-down" aria-hidden="true"></i>
              <i className="fas fa-angle-up" aria-hidden="true"></i>
            </span>
          </p>
        </header>
        <div id="add-card" className="is-collapsible" data-parent="installation-accordion">
          <div className="card-content">
            <div className="content has-padding-top-small has has-padding-bottom-small">
              <h4>Edit your shortmojis</h4>
              <ul className="has-padding-bottom-small">
                <li><b>Change</b> an existing shortmoji by updating its shortcode textbox below.</li>
                <li><b>Add</b> to your shortmojis by clicking "Add", choosing an emoji, and then writing a new shortcode for it.</li>
                <li><b>Delete</b> a shortmoji by clicking the trash icon next to its textbox.</li>
              </ul>
              <p className="is-size-6 has-text-info has-text-centered">{this.props.baseEmojis.length.toLocaleString()} shortmojis</p>
              <AutoSizer className="AutoSizer has-padding-top-small" style={{width: 'auto', height: 'auto'}}>
                {({ height, width }) => (
                  <Grid
                    ref={this.state.gridRef}
                    columnCount={COLUMN_COUNT}
                    columnWidth={Math.round(width / COLUMN_COUNT)}
                    height={ROW_HEIGHT * Math.min(ROW_COUNT, MAX_ROWS)}
                    rowCount={ROW_COUNT}
                    rowHeight={ROW_HEIGHT}
                    width={width}
                  >
                    {Cell}
                  </Grid>
                )}
              </AutoSizer>
            </div>
          </div>
        </div>

        {/* Add modal */}
        <EmojiPicker
          active={this.state.pickerVisible}
          setPickerVisibility={this.setPickerVisibility}
          skin={this.props.skin}
          action={this.addEmoji}
        />
      </div>
    );
  }
}

const mapSizesToProps = (sizes) => ({
  isGtTablet: withSizes.isGtTablet(sizes),
})

export default withSizes(mapSizesToProps)(EditEmojis);
