import React, { Component } from 'react';

import './styles.scss';


class Features extends Component {
  render() {
    return (
      <section className="section Features is-large has-text-white">
        <div className="container">
          <div className="content">
            <h2>Features</h2>
          </div>

          <div className="columns is-multiline is-desktop is-8">
            {/*Feature 1*/}
            <div className="column is-half-desktop has-padding-medium">
              <div className="content">
                <h3 className="is-size-4-touch"><span role="img" aria-label="Swap">🔄</span>&nbsp;&nbsp;Emoji Replacement</h3>
                <p>Tired of swiping through screens of emojis just to find <span role="img" aria-label="Eggplant">🍆</span>? Just type <b>:eggplant:</b> and watch the magic happen.</p>
              </div>
            </div>
            {/*Feature 2*/}
            <div className="column is-half-desktop has-padding-medium">
              <div className="content">
                <h3 className="is-size-4-touch"><span role="img" aria-label="Artist">👩‍🎨</span>&nbsp;&nbsp;Fully Customizable</h3>
                <p>Create your own shortcodes for emojis, or choose a default skin tone and start with thousands of existing ones.</p>
              </div>
            </div>
            {/*Feature 3*/}
            <div className="column is-half-desktop has-padding-medium">
              <div className="content">
                <h3 className="is-size-4-touch"><span role="img" aria-label="Computer">💻</span>&nbsp;&nbsp;Works Across Devices</h3>
                <p>Shortmoji works across your Mac and iCloud-enabled iOS devices so you can quickly type emoji shortcodes at your desk or on the go.</p>
              </div>
            </div>
            {/*Feature 4*/}
            <div className="column is-half-desktop has-padding-medium">
              <div className="content">
                <h3 className="is-size-4-touch"><span role="img" aria-label="Hush">🤫</span>&nbsp;&nbsp;Privacy, Privacy, Privacy</h3>
                <p>Shortmoji works by modifying your text replacements in System Preferences, so there's no application watching what you type.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Features;
