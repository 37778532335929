import React, { Component } from 'react';
import SetupBase from './../SetupBase';
import EditEmojis from './../EditEmojis';
import DownloadInstall from './../DownloadInstall';

import defaultEmojis from './../../emojis/default';
import slackEmojis from './../../emojis/slack';
import extendedEmojis from './../../emojis/extended';

import appleData from 'emoji-mart/data/apple.json';
import bulmaCollapsible from '@creativebulma/bulma-collapsible';

import './styles.scss';


class Installation extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      base: 'default',
      skin: 1,
      beforeShortcode: ':',
      afterShortcode: ':',
      emojis: this.buildEmojisWithSkin(this.getEmojisFromBase('default'), 1),
    };

    this.handleBeforeShortcodeChange = this.handleBeforeShortcodeChange.bind(this);
    this.handleAfterShortcodeChange = this.handleAfterShortcodeChange.bind(this);
    this.handleSkinChange = this.handleSkinChange.bind(this);
    this.handleEmojiBaseChange = this.handleEmojiBaseChange.bind(this);
    this.handleEmojiShortcodeChange = this.handleEmojiShortcodeChange.bind(this);
    this.handleNewEmojiShortcode = this.handleNewEmojiShortcode.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  componentDidMount() {
    bulmaCollapsible.attach('.is-collapsible');
  }

  emojiCount() {
    let count = 0;
    this.state.emojis.forEach((emoji) => {
      if (emoji.shortcode) {
        count += 1;
      }
    });
    return count;
  }

  getEmojisFromBase(base) {
    if (base === 'default') {
      return [...defaultEmojis];
    } else if (base === 'slack') {
      return [...slackEmojis];
    } else if (base === 'extended') {
      return [...extendedEmojis];
    }
    return [];
  }

  getEmojiFromEmojiData(emoji) {
    const skinCodes = ['1F3FB', '1F3FC', '1F3FD', '1F3FE', '1F3FF'];
    const emojiData = appleData.emojis[emoji.id];

    let unicode = emojiData ? emojiData.unified : '';
    if (emojiData.skin_variations && emoji.skin && emoji.skin > 1) {
      const skinCode = skinCodes[emoji.skin - 2];
      unicode = emojiData.skin_variations[skinCode].unified;
    }
    const unicodeParts = unicode.split('-');
    const utfParts = unicodeParts.map(u => String.fromCodePoint(parseInt(u, 16) || ''));
    const utf = utfParts.join('');
    return utf;
  }

  buildEmojisWithSkin(emojis, skin) {
    let tonedEmojis = [];
    emojis.forEach((emoji) => {
      tonedEmojis.push({
        id: emoji.id,
        skin: appleData.emojis[emoji.id].skin_variations ? skin : null,
        shortcode: emoji.shortcode,
      });
    });
    return tonedEmojis;
  }

  buildShortmojiPlist() {
    let emojis = [];
    this.state.emojis.forEach((emojiData) => {
      const emoji = this.getEmojiFromEmojiData(emojiData);
      const emojiShortcode = emojiData.shortcode;
      emojis.push(
        `  <dict>\n` +
        `    <key>phrase</key>\n` +
        `    <string>${emoji}</string>\n` +
        `    <key>shortcut</key>\n` +
        `    <string>${this.state.beforeShortcode}${emojiShortcode}${this.state.afterShortcode}</string>\n` +
        `  </dict>`
      );
    });

    const output =
      `<?xml version="1.0" encoding="UTF-8"?>\n` +
      `<!DOCTYPE plist PUBLIC "-//Apple//DTD PLIST 1.0//EN" "http://www.apple.com/DTDs/PropertyList-1.0.dtd">\n` +
      `<plist version="1.0">\n` +
      `<array>\n` +
      `${emojis.join('\n')}\n` +
      `</array>\n` +
      `</plist>`;
    return output;
  }

  handleBeforeShortcodeChange(event) {
    this.setState({beforeShortcode: event.target.value});
  }

  handleAfterShortcodeChange(event) {
    this.setState({afterShortcode: event.target.value});
  }

  handleSkinChange(skin) {
    this.setState({
      skin: skin,
      emojis: this.buildEmojisWithSkin(this.state.emojis, skin),
    });
  }

  handleEmojiBaseChange(base) {
    const baseEmojis = this.getEmojisFromBase(base);
    this.setState({
      base: base,
      emojis: this.buildEmojisWithSkin(baseEmojis, this.state.skin),
    });
  }

  handleEmojiShortcodeChange(index, value) {
    const newEmojis = [...this.state.emojis];
    if (value === null) {  // Delete emoji if value is null
      newEmojis.splice(index, 1);
    } else {  // Update emoji otherwise
      newEmojis[index].shortcode = value;
    }
    this.setState({emojis: newEmojis});
  }

  handleNewEmojiShortcode(id, skin) {
    const newEmojis = [...this.state.emojis];
    if (id) {
      newEmojis.push({
        id: id,
        skin: skin,
        shortcode: '',
      });
      this.setState({emojis: newEmojis});
    }
  }

  handleDownload() {
    const element = document.createElement('a');
    const plist = this.buildShortmojiPlist();
    const file = new Blob([plist], {type: 'text/xml'});
    element.href = URL.createObjectURL(file);
    element.download = 'Shortmojis.plist';
    document.body.appendChild(element);  // required for this to work in FireFox
    element.click();
  }

  render() {
    return (
      <section className="section Installation is-large">
        <div className="container">
          <div className="content">
            <h2>Installation</h2>
          </div>
          <div id="installation-accordion">
            <SetupBase
              base={this.state.base}
              skin={this.state.skin}
              beforeShortcode={this.state.beforeShortcode}
              afterShortcode={this.state.afterShortcode}
              handleBeforeShortcodeChange={this.handleBeforeShortcodeChange}
              handleAfterShortcodeChange={this.handleAfterShortcodeChange}
              handleSkinChange={this.handleSkinChange}
              handleEmojiBaseChange={this.handleEmojiBaseChange}
            />
            <EditEmojis
              base={this.state.base}
              skin={this.state.skin}
              baseEmojis={this.state.emojis}
              handleEmojiShortcodeChange={this.handleEmojiShortcodeChange}
              handleNewEmojiShortcode={this.handleNewEmojiShortcode}
            />
            <DownloadInstall
              emojiCount={this.emojiCount()}
              beforeShortcode={this.state.beforeShortcode}
              afterShortcode={this.state.afterShortcode}
              handleDownload={this.handleDownload}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default Installation;
