import React, { Component } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import Hero from './../../components/Hero';
import Features from './../../components/Features';
import Installation from './../../components/Installation';
import Footer from './../../components/Footer';

import './styles.scss';


class App extends Component {
  render() {
    return (
      <ParallaxProvider>
        <div className="App">
          <Hero />
          <Features />
          <Installation />
          <Footer />
        </div>
      </ParallaxProvider>
    );
  }
}

export default App;
