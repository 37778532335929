export default [
  {
    id: 'grinning',
    shortcode: 'grinning',
  },
  {
    id: 'grin',
    shortcode: 'grin',
  },
  {
    id: 'joy',
    shortcode: 'joy',
  },
  {
    id: 'rolling_on_the_floor_laughing',
    shortcode: 'rolling_on_the_floor_laughing',
  },
  {
    id: 'smiley',
    shortcode: 'smiley',
  },
  {
    id: 'smile',
    shortcode: 'smile',
  },
  {
    id: 'sweat_smile',
    shortcode: 'sweat_smile',
  },
  {
    id: 'laughing',
    shortcode: 'laughing',
  },
  {
    id: 'laughing',
    shortcode: 'satisfied',
  },
  {
    id: 'wink',
    shortcode: 'wink',
  },
  {
    id: 'blush',
    shortcode: 'blush',
  },
  {
    id: 'yum',
    shortcode: 'yum',
  },
  {
    id: 'sunglasses',
    shortcode: 'sunglasses',
  },
  {
    id: 'heart_eyes',
    shortcode: 'heart_eyes',
  },
  {
    id: 'kissing_heart',
    shortcode: 'kissing_heart',
  },
  {
    id: 'kissing',
    shortcode: 'kissing',
  },
  {
    id: 'kissing_smiling_eyes',
    shortcode: 'kissing_smiling_eyes',
  },
  {
    id: 'kissing_closed_eyes',
    shortcode: 'kissing_closed_eyes',
  },
  {
    id: 'relaxed',
    shortcode: 'relaxed',
  },
  {
    id: 'slightly_smiling_face',
    shortcode: 'slightly_smiling_face',
  },
  {
    id: 'hugging_face',
    shortcode: 'hugging_face',
  },
  {
    id: 'star-struck',
    shortcode: 'star-struck',
  },
  {
    id: 'star-struck',
    shortcode: 'grinning_face_with_star_eyes',
  },
  {
    id: 'thinking_face',
    shortcode: 'thinking_face',
  },
  {
    id: 'face_with_raised_eyebrow',
    shortcode: 'face_with_raised_eyebrow',
  },
  {
    id: 'face_with_raised_eyebrow',
    shortcode: 'face_with_one_eyebrow_raised',
  },
  {
    id: 'neutral_face',
    shortcode: 'neutral_face',
  },
  {
    id: 'expressionless',
    shortcode: 'expressionless',
  },
  {
    id: 'no_mouth',
    shortcode: 'no_mouth',
  },
  {
    id: 'face_with_rolling_eyes',
    shortcode: 'face_with_rolling_eyes',
  },
  {
    id: 'smirk',
    shortcode: 'smirk',
  },
  {
    id: 'persevere',
    shortcode: 'persevere',
  },
  {
    id: 'disappointed_relieved',
    shortcode: 'disappointed_relieved',
  },
  {
    id: 'open_mouth',
    shortcode: 'open_mouth',
  },
  {
    id: 'zipper_mouth_face',
    shortcode: 'zipper_mouth_face',
  },
  {
    id: 'hushed',
    shortcode: 'hushed',
  },
  {
    id: 'sleepy',
    shortcode: 'sleepy',
  },
  {
    id: 'tired_face',
    shortcode: 'tired_face',
  },
  {
    id: 'sleeping',
    shortcode: 'sleeping',
  },
  {
    id: 'relieved',
    shortcode: 'relieved',
  },
  {
    id: 'stuck_out_tongue',
    shortcode: 'stuck_out_tongue',
  },
  {
    id: 'stuck_out_tongue_winking_eye',
    shortcode: 'stuck_out_tongue_winking_eye',
  },
  {
    id: 'stuck_out_tongue_closed_eyes',
    shortcode: 'stuck_out_tongue_closed_eyes',
  },
  {
    id: 'drooling_face',
    shortcode: 'drooling_face',
  },
  {
    id: 'unamused',
    shortcode: 'unamused',
  },
  {
    id: 'sweat',
    shortcode: 'sweat',
  },
  {
    id: 'pensive',
    shortcode: 'pensive',
  },
  {
    id: 'confused',
    shortcode: 'confused',
  },
  {
    id: 'upside_down_face',
    shortcode: 'upside_down_face',
  },
  {
    id: 'money_mouth_face',
    shortcode: 'money_mouth_face',
  },
  {
    id: 'astonished',
    shortcode: 'astonished',
  },
  {
    id: 'white_frowning_face',
    shortcode: 'white_frowning_face',
  },
  {
    id: 'slightly_frowning_face',
    shortcode: 'slightly_frowning_face',
  },
  {
    id: 'confounded',
    shortcode: 'confounded',
  },
  {
    id: 'disappointed',
    shortcode: 'disappointed',
  },
  {
    id: 'worried',
    shortcode: 'worried',
  },
  {
    id: 'triumph',
    shortcode: 'triumph',
  },
  {
    id: 'cry',
    shortcode: 'cry',
  },
  {
    id: 'sob',
    shortcode: 'sob',
  },
  {
    id: 'frowning',
    shortcode: 'frowning',
  },
  {
    id: 'anguished',
    shortcode: 'anguished',
  },
  {
    id: 'fearful',
    shortcode: 'fearful',
  },
  {
    id: 'weary',
    shortcode: 'weary',
  },
  {
    id: 'exploding_head',
    shortcode: 'exploding_head',
  },
  {
    id: 'exploding_head',
    shortcode: 'shocked_face_with_exploding_head',
  },
  {
    id: 'grimacing',
    shortcode: 'grimacing',
  },
  {
    id: 'cold_sweat',
    shortcode: 'cold_sweat',
  },
  {
    id: 'scream',
    shortcode: 'scream',
  },
  {
    id: 'flushed',
    shortcode: 'flushed',
  },
  {
    id: 'zany_face',
    shortcode: 'zany_face',
  },
  {
    id: 'zany_face',
    shortcode: 'grinning_face_with_one_large_and_one_small_eye',
  },
  {
    id: 'dizzy_face',
    shortcode: 'dizzy_face',
  },
  {
    id: 'rage',
    shortcode: 'rage',
  },
  {
    id: 'angry',
    shortcode: 'angry',
  },
  {
    id: 'face_with_symbols_on_mouth',
    shortcode: 'face_with_symbols_on_mouth',
  },
  {
    id: 'face_with_symbols_on_mouth',
    shortcode: 'serious_face_with_symbols_covering_mouth',
  },
  {
    id: 'mask',
    shortcode: 'mask',
  },
  {
    id: 'face_with_thermometer',
    shortcode: 'face_with_thermometer',
  },
  {
    id: 'face_with_head_bandage',
    shortcode: 'face_with_head_bandage',
  },
  {
    id: 'nauseated_face',
    shortcode: 'nauseated_face',
  },
  {
    id: 'face_vomiting',
    shortcode: 'face_vomiting',
  },
  {
    id: 'face_vomiting',
    shortcode: 'face_with_open_mouth_vomiting',
  },
  {
    id: 'sneezing_face',
    shortcode: 'sneezing_face',
  },
  {
    id: 'innocent',
    shortcode: 'innocent',
  },
  {
    id: 'face_with_cowboy_hat',
    shortcode: 'face_with_cowboy_hat',
  },
  {
    id: 'clown_face',
    shortcode: 'clown_face',
  },
  {
    id: 'lying_face',
    shortcode: 'lying_face',
  },
  {
    id: 'shushing_face',
    shortcode: 'shushing_face',
  },
  {
    id: 'shushing_face',
    shortcode: 'face_with_finger_covering_closed_lips',
  },
  {
    id: 'face_with_hand_over_mouth',
    shortcode: 'face_with_hand_over_mouth',
  },
  {
    id: 'face_with_hand_over_mouth',
    shortcode: 'smiling_face_with_smiling_eyes_and_hand_covering_mouth',
  },
  {
    id: 'face_with_monocle',
    shortcode: 'face_with_monocle',
  },
  {
    id: 'nerd_face',
    shortcode: 'nerd_face',
  },
  {
    id: 'smiling_imp',
    shortcode: 'smiling_imp',
  },
  {
    id: 'imp',
    shortcode: 'imp',
  },
  {
    id: 'japanese_ogre',
    shortcode: 'japanese_ogre',
  },
  {
    id: 'japanese_goblin',
    shortcode: 'japanese_goblin',
  },
  {
    id: 'skull',
    shortcode: 'skull',
  },
  {
    id: 'skull_and_crossbones',
    shortcode: 'skull_and_crossbones',
  },
  {
    id: 'ghost',
    shortcode: 'ghost',
  },
  {
    id: 'alien',
    shortcode: 'alien',
  },
  {
    id: 'space_invader',
    shortcode: 'space_invader',
  },
  {
    id: 'robot_face',
    shortcode: 'robot_face',
  },
  {
    id: 'hankey',
    shortcode: 'hankey',
  },
  {
    id: 'hankey',
    shortcode: 'poop',
  },
  {
    id: 'hankey',
    shortcode: 'shit',
  },
  {
    id: 'smiley_cat',
    shortcode: 'smiley_cat',
  },
  {
    id: 'smile_cat',
    shortcode: 'smile_cat',
  },
  {
    id: 'joy_cat',
    shortcode: 'joy_cat',
  },
  {
    id: 'heart_eyes_cat',
    shortcode: 'heart_eyes_cat',
  },
  {
    id: 'smirk_cat',
    shortcode: 'smirk_cat',
  },
  {
    id: 'kissing_cat',
    shortcode: 'kissing_cat',
  },
  {
    id: 'scream_cat',
    shortcode: 'scream_cat',
  },
  {
    id: 'crying_cat_face',
    shortcode: 'crying_cat_face',
  },
  {
    id: 'pouting_cat',
    shortcode: 'pouting_cat',
  },
  {
    id: 'see_no_evil',
    shortcode: 'see_no_evil',
  },
  {
    id: 'hear_no_evil',
    shortcode: 'hear_no_evil',
  },
  {
    id: 'speak_no_evil',
    shortcode: 'speak_no_evil',
  },
  {
    id: 'baby',
    shortcode: 'baby',
  },
  {
    id: 'child',
    shortcode: 'child',
  },
  {
    id: 'boy',
    shortcode: 'boy',
  },
  {
    id: 'girl',
    shortcode: 'girl',
  },
  {
    id: 'adult',
    shortcode: 'adult',
  },
  {
    id: 'man',
    shortcode: 'man',
  },
  {
    id: 'woman',
    shortcode: 'woman',
  },
  {
    id: 'older_adult',
    shortcode: 'older_adult',
  },
  {
    id: 'older_man',
    shortcode: 'older_man',
  },
  {
    id: 'older_woman',
    shortcode: 'older_woman',
  },
  {
    id: 'male-doctor',
    shortcode: 'male-doctor',
  },
  {
    id: 'female-doctor',
    shortcode: 'female-doctor',
  },
  {
    id: 'male-student',
    shortcode: 'male-student',
  },
  {
    id: 'female-student',
    shortcode: 'female-student',
  },
  {
    id: 'male-teacher',
    shortcode: 'male-teacher',
  },
  {
    id: 'female-teacher',
    shortcode: 'female-teacher',
  },
  {
    id: 'male-judge',
    shortcode: 'male-judge',
  },
  {
    id: 'female-judge',
    shortcode: 'female-judge',
  },
  {
    id: 'male-farmer',
    shortcode: 'male-farmer',
  },
  {
    id: 'female-farmer',
    shortcode: 'female-farmer',
  },
  {
    id: 'male-cook',
    shortcode: 'male-cook',
  },
  {
    id: 'female-cook',
    shortcode: 'female-cook',
  },
  {
    id: 'male-mechanic',
    shortcode: 'male-mechanic',
  },
  {
    id: 'female-mechanic',
    shortcode: 'female-mechanic',
  },
  {
    id: 'male-factory-worker',
    shortcode: 'male-factory-worker',
  },
  {
    id: 'female-factory-worker',
    shortcode: 'female-factory-worker',
  },
  {
    id: 'male-office-worker',
    shortcode: 'male-office-worker',
  },
  {
    id: 'female-office-worker',
    shortcode: 'female-office-worker',
  },
  {
    id: 'male-scientist',
    shortcode: 'male-scientist',
  },
  {
    id: 'female-scientist',
    shortcode: 'female-scientist',
  },
  {
    id: 'male-technologist',
    shortcode: 'male-technologist',
  },
  {
    id: 'female-technologist',
    shortcode: 'female-technologist',
  },
  {
    id: 'male-singer',
    shortcode: 'male-singer',
  },
  {
    id: 'female-singer',
    shortcode: 'female-singer',
  },
  {
    id: 'male-artist',
    shortcode: 'male-artist',
  },
  {
    id: 'female-artist',
    shortcode: 'female-artist',
  },
  {
    id: 'male-pilot',
    shortcode: 'male-pilot',
  },
  {
    id: 'female-pilot',
    shortcode: 'female-pilot',
  },
  {
    id: 'male-astronaut',
    shortcode: 'male-astronaut',
  },
  {
    id: 'female-astronaut',
    shortcode: 'female-astronaut',
  },
  {
    id: 'male-firefighter',
    shortcode: 'male-firefighter',
  },
  {
    id: 'female-firefighter',
    shortcode: 'female-firefighter',
  },
  {
    id: 'cop',
    shortcode: 'cop',
  },
  {
    id: 'male-police-officer',
    shortcode: 'male-police-officer',
  },
  {
    id: 'female-police-officer',
    shortcode: 'female-police-officer',
  },
  {
    id: 'sleuth_or_spy',
    shortcode: 'sleuth_or_spy',
  },
  {
    id: 'male-detective',
    shortcode: 'male-detective',
  },
  {
    id: 'female-detective',
    shortcode: 'female-detective',
  },
  {
    id: 'guardsman',
    shortcode: 'guardsman',
  },
  {
    id: 'male-guard',
    shortcode: 'male-guard',
  },
  {
    id: 'female-guard',
    shortcode: 'female-guard',
  },
  {
    id: 'construction_worker',
    shortcode: 'construction_worker',
  },
  {
    id: 'male-construction-worker',
    shortcode: 'male-construction-worker',
  },
  {
    id: 'female-construction-worker',
    shortcode: 'female-construction-worker',
  },
  {
    id: 'prince',
    shortcode: 'prince',
  },
  {
    id: 'princess',
    shortcode: 'princess',
  },
  {
    id: 'man_with_turban',
    shortcode: 'man_with_turban',
  },
  {
    id: 'man-wearing-turban',
    shortcode: 'man-wearing-turban',
  },
  {
    id: 'woman-wearing-turban',
    shortcode: 'woman-wearing-turban',
  },
  {
    id: 'man_with_gua_pi_mao',
    shortcode: 'man_with_gua_pi_mao',
  },
  {
    id: 'person_with_headscarf',
    shortcode: 'person_with_headscarf',
  },
  {
    id: 'bearded_person',
    shortcode: 'bearded_person',
  },
  {
    id: 'person_with_blond_hair',
    shortcode: 'person_with_blond_hair',
  },
  {
    id: 'blond-haired-man',
    shortcode: 'blond-haired-man',
  },
  {
    id: 'blond-haired-woman',
    shortcode: 'blond-haired-woman',
  },
  {
    id: 'man_in_tuxedo',
    shortcode: 'man_in_tuxedo',
  },
  {
    id: 'bride_with_veil',
    shortcode: 'bride_with_veil',
  },
  {
    id: 'pregnant_woman',
    shortcode: 'pregnant_woman',
  },
  {
    id: 'breast-feeding',
    shortcode: 'breast-feeding',
  },
  {
    id: 'angel',
    shortcode: 'angel',
  },
  {
    id: 'santa',
    shortcode: 'santa',
  },
  {
    id: 'mrs_claus',
    shortcode: 'mrs_claus',
  },
  {
    id: 'mrs_claus',
    shortcode: 'mother_christmas',
  },
  {
    id: 'mage',
    shortcode: 'mage',
  },
  {
    id: 'female_mage',
    shortcode: 'female_mage',
  },
  {
    id: 'male_mage',
    shortcode: 'male_mage',
  },
  {
    id: 'fairy',
    shortcode: 'fairy',
  },
  {
    id: 'female_fairy',
    shortcode: 'female_fairy',
  },
  {
    id: 'male_fairy',
    shortcode: 'male_fairy',
  },
  {
    id: 'vampire',
    shortcode: 'vampire',
  },
  {
    id: 'female_vampire',
    shortcode: 'female_vampire',
  },
  {
    id: 'male_vampire',
    shortcode: 'male_vampire',
  },
  {
    id: 'merperson',
    shortcode: 'merperson',
  },
  {
    id: 'mermaid',
    shortcode: 'mermaid',
  },
  {
    id: 'merman',
    shortcode: 'merman',
  },
  {
    id: 'elf',
    shortcode: 'elf',
  },
  {
    id: 'female_elf',
    shortcode: 'female_elf',
  },
  {
    id: 'male_elf',
    shortcode: 'male_elf',
  },
  {
    id: 'genie',
    shortcode: 'genie',
  },
  {
    id: 'female_genie',
    shortcode: 'female_genie',
  },
  {
    id: 'male_genie',
    shortcode: 'male_genie',
  },
  {
    id: 'zombie',
    shortcode: 'zombie',
  },
  {
    id: 'female_zombie',
    shortcode: 'female_zombie',
  },
  {
    id: 'male_zombie',
    shortcode: 'male_zombie',
  },
  {
    id: 'person_frowning',
    shortcode: 'person_frowning',
  },
  {
    id: 'man-frowning',
    shortcode: 'man-frowning',
  },
  {
    id: 'woman-frowning',
    shortcode: 'woman-frowning',
  },
  {
    id: 'person_with_pouting_face',
    shortcode: 'person_with_pouting_face',
  },
  {
    id: 'man-pouting',
    shortcode: 'man-pouting',
  },
  {
    id: 'woman-pouting',
    shortcode: 'woman-pouting',
  },
  {
    id: 'no_good',
    shortcode: 'no_good',
  },
  {
    id: 'man-gesturing-no',
    shortcode: 'man-gesturing-no',
  },
  {
    id: 'woman-gesturing-no',
    shortcode: 'woman-gesturing-no',
  },
  {
    id: 'ok_woman',
    shortcode: 'ok_woman',
  },
  {
    id: 'man-gesturing-ok',
    shortcode: 'man-gesturing-ok',
  },
  {
    id: 'woman-gesturing-ok',
    shortcode: 'woman-gesturing-ok',
  },
  {
    id: 'information_desk_person',
    shortcode: 'information_desk_person',
  },
  {
    id: 'man-tipping-hand',
    shortcode: 'man-tipping-hand',
  },
  {
    id: 'woman-tipping-hand',
    shortcode: 'woman-tipping-hand',
  },
  {
    id: 'raising_hand',
    shortcode: 'raising_hand',
  },
  {
    id: 'man-raising-hand',
    shortcode: 'man-raising-hand',
  },
  {
    id: 'woman-raising-hand',
    shortcode: 'woman-raising-hand',
  },
  {
    id: 'bow',
    shortcode: 'bow',
  },
  {
    id: 'man-bowing',
    shortcode: 'man-bowing',
  },
  {
    id: 'woman-bowing',
    shortcode: 'woman-bowing',
  },
  {
    id: 'face_palm',
    shortcode: 'face_palm',
  },
  {
    id: 'man-facepalming',
    shortcode: 'man-facepalming',
  },
  {
    id: 'woman-facepalming',
    shortcode: 'woman-facepalming',
  },
  {
    id: 'shrug',
    shortcode: 'shrug',
  },
  {
    id: 'man-shrugging',
    shortcode: 'man-shrugging',
  },
  {
    id: 'woman-shrugging',
    shortcode: 'woman-shrugging',
  },
  {
    id: 'massage',
    shortcode: 'massage',
  },
  {
    id: 'man-getting-massage',
    shortcode: 'man-getting-massage',
  },
  {
    id: 'woman-getting-massage',
    shortcode: 'woman-getting-massage',
  },
  {
    id: 'haircut',
    shortcode: 'haircut',
  },
  {
    id: 'man-getting-haircut',
    shortcode: 'man-getting-haircut',
  },
  {
    id: 'woman-getting-haircut',
    shortcode: 'woman-getting-haircut',
  },
  {
    id: 'walking',
    shortcode: 'walking',
  },
  {
    id: 'man-walking',
    shortcode: 'man-walking',
  },
  {
    id: 'woman-walking',
    shortcode: 'woman-walking',
  },
  {
    id: 'runner',
    shortcode: 'runner',
  },
  {
    id: 'runner',
    shortcode: 'running',
  },
  {
    id: 'man-running',
    shortcode: 'man-running',
  },
  {
    id: 'woman-running',
    shortcode: 'woman-running',
  },
  {
    id: 'dancer',
    shortcode: 'dancer',
  },
  {
    id: 'man_dancing',
    shortcode: 'man_dancing',
  },
  {
    id: 'dancers',
    shortcode: 'dancers',
  },
  {
    id: 'man-with-bunny-ears-partying',
    shortcode: 'man-with-bunny-ears-partying',
  },
  {
    id: 'woman-with-bunny-ears-partying',
    shortcode: 'woman-with-bunny-ears-partying',
  },
  {
    id: 'person_in_steamy_room',
    shortcode: 'person_in_steamy_room',
  },
  {
    id: 'woman_in_steamy_room',
    shortcode: 'woman_in_steamy_room',
  },
  {
    id: 'man_in_steamy_room',
    shortcode: 'man_in_steamy_room',
  },
  {
    id: 'person_climbing',
    shortcode: 'person_climbing',
  },
  {
    id: 'woman_climbing',
    shortcode: 'woman_climbing',
  },
  {
    id: 'man_climbing',
    shortcode: 'man_climbing',
  },
  {
    id: 'person_in_lotus_position',
    shortcode: 'person_in_lotus_position',
  },
  {
    id: 'woman_in_lotus_position',
    shortcode: 'woman_in_lotus_position',
  },
  {
    id: 'man_in_lotus_position',
    shortcode: 'man_in_lotus_position',
  },
  {
    id: 'bath',
    shortcode: 'bath',
  },
  {
    id: 'sleeping_accommodation',
    shortcode: 'sleeping_accommodation',
  },
  {
    id: 'man_in_business_suit_levitating',
    shortcode: 'man_in_business_suit_levitating',
  },
  {
    id: 'speaking_head_in_silhouette',
    shortcode: 'speaking_head_in_silhouette',
  },
  {
    id: 'bust_in_silhouette',
    shortcode: 'bust_in_silhouette',
  },
  {
    id: 'busts_in_silhouette',
    shortcode: 'busts_in_silhouette',
  },
  {
    id: 'fencer',
    shortcode: 'fencer',
  },
  {
    id: 'horse_racing',
    shortcode: 'horse_racing',
  },
  {
    id: 'skier',
    shortcode: 'skier',
  },
  {
    id: 'snowboarder',
    shortcode: 'snowboarder',
  },
  {
    id: 'golfer',
    shortcode: 'golfer',
  },
  {
    id: 'man-golfing',
    shortcode: 'man-golfing',
  },
  {
    id: 'woman-golfing',
    shortcode: 'woman-golfing',
  },
  {
    id: 'surfer',
    shortcode: 'surfer',
  },
  {
    id: 'man-surfing',
    shortcode: 'man-surfing',
  },
  {
    id: 'woman-surfing',
    shortcode: 'woman-surfing',
  },
  {
    id: 'rowboat',
    shortcode: 'rowboat',
  },
  {
    id: 'man-rowing-boat',
    shortcode: 'man-rowing-boat',
  },
  {
    id: 'woman-rowing-boat',
    shortcode: 'woman-rowing-boat',
  },
  {
    id: 'swimmer',
    shortcode: 'swimmer',
  },
  {
    id: 'man-swimming',
    shortcode: 'man-swimming',
  },
  {
    id: 'woman-swimming',
    shortcode: 'woman-swimming',
  },
  {
    id: 'person_with_ball',
    shortcode: 'person_with_ball',
  },
  {
    id: 'man-bouncing-ball',
    shortcode: 'man-bouncing-ball',
  },
  {
    id: 'woman-bouncing-ball',
    shortcode: 'woman-bouncing-ball',
  },
  {
    id: 'weight_lifter',
    shortcode: 'weight_lifter',
  },
  {
    id: 'man-lifting-weights',
    shortcode: 'man-lifting-weights',
  },
  {
    id: 'woman-lifting-weights',
    shortcode: 'woman-lifting-weights',
  },
  {
    id: 'bicyclist',
    shortcode: 'bicyclist',
  },
  {
    id: 'man-biking',
    shortcode: 'man-biking',
  },
  {
    id: 'woman-biking',
    shortcode: 'woman-biking',
  },
  {
    id: 'mountain_bicyclist',
    shortcode: 'mountain_bicyclist',
  },
  {
    id: 'man-mountain-biking',
    shortcode: 'man-mountain-biking',
  },
  {
    id: 'woman-mountain-biking',
    shortcode: 'woman-mountain-biking',
  },
  {
    id: 'racing_car',
    shortcode: 'racing_car',
  },
  {
    id: 'racing_motorcycle',
    shortcode: 'racing_motorcycle',
  },
  {
    id: 'person_doing_cartwheel',
    shortcode: 'person_doing_cartwheel',
  },
  {
    id: 'man-cartwheeling',
    shortcode: 'man-cartwheeling',
  },
  {
    id: 'woman-cartwheeling',
    shortcode: 'woman-cartwheeling',
  },
  {
    id: 'wrestlers',
    shortcode: 'wrestlers',
  },
  {
    id: 'man-wrestling',
    shortcode: 'man-wrestling',
  },
  {
    id: 'woman-wrestling',
    shortcode: 'woman-wrestling',
  },
  {
    id: 'water_polo',
    shortcode: 'water_polo',
  },
  {
    id: 'man-playing-water-polo',
    shortcode: 'man-playing-water-polo',
  },
  {
    id: 'woman-playing-water-polo',
    shortcode: 'woman-playing-water-polo',
  },
  {
    id: 'handball',
    shortcode: 'handball',
  },
  {
    id: 'man-playing-handball',
    shortcode: 'man-playing-handball',
  },
  {
    id: 'woman-playing-handball',
    shortcode: 'woman-playing-handball',
  },
  {
    id: 'juggling',
    shortcode: 'juggling',
  },
  {
    id: 'man-juggling',
    shortcode: 'man-juggling',
  },
  {
    id: 'woman-juggling',
    shortcode: 'woman-juggling',
  },
  {
    id: 'couple',
    shortcode: 'couple',
  },
  {
    id: 'couple',
    shortcode: 'man_and_woman_holding_hands',
  },
  {
    id: 'two_men_holding_hands',
    shortcode: 'two_men_holding_hands',
  },
  {
    id: 'two_women_holding_hands',
    shortcode: 'two_women_holding_hands',
  },
  {
    id: 'couplekiss',
    shortcode: 'couplekiss',
  },
  {
    id: 'woman-kiss-man',
    shortcode: 'woman-kiss-man',
  },
  {
    id: 'man-kiss-man',
    shortcode: 'man-kiss-man',
  },
  {
    id: 'woman-kiss-woman',
    shortcode: 'woman-kiss-woman',
  },
  {
    id: 'couple_with_heart',
    shortcode: 'couple_with_heart',
  },
  {
    id: 'woman-heart-man',
    shortcode: 'woman-heart-man',
  },
  {
    id: 'man-heart-man',
    shortcode: 'man-heart-man',
  },
  {
    id: 'woman-heart-woman',
    shortcode: 'woman-heart-woman',
  },
  {
    id: 'family',
    shortcode: 'family',
  },
  {
    id: 'man-woman-boy',
    shortcode: 'man-woman-boy',
  },
  {
    id: 'man-woman-girl',
    shortcode: 'man-woman-girl',
  },
  {
    id: 'man-woman-girl-boy',
    shortcode: 'man-woman-girl-boy',
  },
  {
    id: 'man-woman-boy-boy',
    shortcode: 'man-woman-boy-boy',
  },
  {
    id: 'man-woman-girl-girl',
    shortcode: 'man-woman-girl-girl',
  },
  {
    id: 'man-man-boy',
    shortcode: 'man-man-boy',
  },
  {
    id: 'man-man-girl',
    shortcode: 'man-man-girl',
  },
  {
    id: 'man-man-girl-boy',
    shortcode: 'man-man-girl-boy',
  },
  {
    id: 'man-man-boy-boy',
    shortcode: 'man-man-boy-boy',
  },
  {
    id: 'man-man-girl-girl',
    shortcode: 'man-man-girl-girl',
  },
  {
    id: 'woman-woman-boy',
    shortcode: 'woman-woman-boy',
  },
  {
    id: 'woman-woman-girl',
    shortcode: 'woman-woman-girl',
  },
  {
    id: 'woman-woman-girl-boy',
    shortcode: 'woman-woman-girl-boy',
  },
  {
    id: 'woman-woman-boy-boy',
    shortcode: 'woman-woman-boy-boy',
  },
  {
    id: 'woman-woman-girl-girl',
    shortcode: 'woman-woman-girl-girl',
  },
  {
    id: 'man-boy',
    shortcode: 'man-boy',
  },
  {
    id: 'man-boy-boy',
    shortcode: 'man-boy-boy',
  },
  {
    id: 'man-girl',
    shortcode: 'man-girl',
  },
  {
    id: 'man-girl-boy',
    shortcode: 'man-girl-boy',
  },
  {
    id: 'man-girl-girl',
    shortcode: 'man-girl-girl',
  },
  {
    id: 'woman-boy',
    shortcode: 'woman-boy',
  },
  {
    id: 'woman-boy-boy',
    shortcode: 'woman-boy-boy',
  },
  {
    id: 'woman-girl',
    shortcode: 'woman-girl',
  },
  {
    id: 'woman-girl-boy',
    shortcode: 'woman-girl-boy',
  },
  {
    id: 'woman-girl-girl',
    shortcode: 'woman-girl-girl',
  },
  {
    id: 'selfie',
    shortcode: 'selfie',
  },
  {
    id: 'muscle',
    shortcode: 'muscle',
  },
  {
    id: 'point_left',
    shortcode: 'point_left',
  },
  {
    id: 'point_right',
    shortcode: 'point_right',
  },
  {
    id: 'point_up',
    shortcode: 'point_up',
  },
  {
    id: 'point_up_2',
    shortcode: 'point_up_2',
  },
  {
    id: 'middle_finger',
    shortcode: 'middle_finger',
  },
  {
    id: 'middle_finger',
    shortcode: 'reversed_hand_with_middle_finger_extended',
  },
  {
    id: 'point_down',
    shortcode: 'point_down',
  },
  {
    id: 'v',
    shortcode: 'v',
  },
  {
    id: 'crossed_fingers',
    shortcode: 'crossed_fingers',
  },
  {
    id: 'crossed_fingers',
    shortcode: 'hand_with_index_and_middle_fingers_crossed',
  },
  {
    id: 'spock-hand',
    shortcode: 'spock-hand',
  },
  {
    id: 'the_horns',
    shortcode: 'the_horns',
  },
  {
    id: 'the_horns',
    shortcode: 'sign_of_the_horns',
  },
  {
    id: 'call_me_hand',
    shortcode: 'call_me_hand',
  },
  {
    id: 'raised_hand_with_fingers_splayed',
    shortcode: 'raised_hand_with_fingers_splayed',
  },
  {
    id: 'hand',
    shortcode: 'hand',
  },
  {
    id: 'hand',
    shortcode: 'raised_hand',
  },
  {
    id: 'ok_hand',
    shortcode: 'ok_hand',
  },
  {
    id: '+1',
    shortcode: '+1',
  },
  {
    id: '+1',
    shortcode: 'thumbsup',
  },
  {
    id: '+1',
    shortcode: 'thumbs_up',
  },
  {
    id: '-1',
    shortcode: '-1',
  },
  {
    id: '-1',
    shortcode: 'thumbsdown',
  },
  {
    id: '-1',
    shortcode: 'thumbs_down',
  },
  {
    id: 'fist',
    shortcode: 'fist',
  },
  {
    id: 'facepunch',
    shortcode: 'facepunch',
  },
  {
    id: 'facepunch',
    shortcode: 'punch',
  },
  {
    id: 'left-facing_fist',
    shortcode: 'left-facing_fist',
  },
  {
    id: 'right-facing_fist',
    shortcode: 'right-facing_fist',
  },
  {
    id: 'raised_back_of_hand',
    shortcode: 'raised_back_of_hand',
  },
  {
    id: 'wave',
    shortcode: 'wave',
  },
  {
    id: 'i_love_you_hand_sign',
    shortcode: 'i_love_you_hand_sign',
  },
  {
    id: 'writing_hand',
    shortcode: 'writing_hand',
  },
  {
    id: 'clap',
    shortcode: 'clap',
  },
  {
    id: 'open_hands',
    shortcode: 'open_hands',
  },
  {
    id: 'raised_hands',
    shortcode: 'raised_hands',
  },
  {
    id: 'palms_up_together',
    shortcode: 'palms_up_together',
  },
  {
    id: 'pray',
    shortcode: 'pray',
  },
  {
    id: 'handshake',
    shortcode: 'handshake',
  },
  {
    id: 'nail_care',
    shortcode: 'nail_care',
  },
  {
    id: 'ear',
    shortcode: 'ear',
  },
  {
    id: 'nose',
    shortcode: 'nose',
  },
  {
    id: 'footprints',
    shortcode: 'footprints',
  },
  {
    id: 'eyes',
    shortcode: 'eyes',
  },
  {
    id: 'eye',
    shortcode: 'eye',
  },
  {
    id: 'eye-in-speech-bubble',
    shortcode: 'eye-in-speech-bubble',
  },
  {
    id: 'brain',
    shortcode: 'brain',
  },
  {
    id: 'tongue',
    shortcode: 'tongue',
  },
  {
    id: 'lips',
    shortcode: 'lips',
  },
  {
    id: 'kiss',
    shortcode: 'kiss',
  },
  {
    id: 'cupid',
    shortcode: 'cupid',
  },
  {
    id: 'heart',
    shortcode: 'heart',
  },
  {
    id: 'heartbeat',
    shortcode: 'heartbeat',
  },
  {
    id: 'broken_heart',
    shortcode: 'broken_heart',
  },
  {
    id: 'two_hearts',
    shortcode: 'two_hearts',
  },
  {
    id: 'sparkling_heart',
    shortcode: 'sparkling_heart',
  },
  {
    id: 'heartpulse',
    shortcode: 'heartpulse',
  },
  {
    id: 'blue_heart',
    shortcode: 'blue_heart',
  },
  {
    id: 'green_heart',
    shortcode: 'green_heart',
  },
  {
    id: 'yellow_heart',
    shortcode: 'yellow_heart',
  },
  {
    id: 'orange_heart',
    shortcode: 'orange_heart',
  },
  {
    id: 'purple_heart',
    shortcode: 'purple_heart',
  },
  {
    id: 'black_heart',
    shortcode: 'black_heart',
  },
  {
    id: 'gift_heart',
    shortcode: 'gift_heart',
  },
  {
    id: 'revolving_hearts',
    shortcode: 'revolving_hearts',
  },
  {
    id: 'heart_decoration',
    shortcode: 'heart_decoration',
  },
  {
    id: 'heavy_heart_exclamation_mark_ornament',
    shortcode: 'heavy_heart_exclamation_mark_ornament',
  },
  {
    id: 'love_letter',
    shortcode: 'love_letter',
  },
  {
    id: 'zzz',
    shortcode: 'zzz',
  },
  {
    id: 'anger',
    shortcode: 'anger',
  },
  {
    id: 'bomb',
    shortcode: 'bomb',
  },
  {
    id: 'boom',
    shortcode: 'boom',
  },
  {
    id: 'boom',
    shortcode: 'collision',
  },
  {
    id: 'sweat_drops',
    shortcode: 'sweat_drops',
  },
  {
    id: 'dash',
    shortcode: 'dash',
  },
  {
    id: 'dizzy',
    shortcode: 'dizzy',
  },
  {
    id: 'speech_balloon',
    shortcode: 'speech_balloon',
  },
  {
    id: 'left_speech_bubble',
    shortcode: 'left_speech_bubble',
  },
  {
    id: 'right_anger_bubble',
    shortcode: 'right_anger_bubble',
  },
  {
    id: 'thought_balloon',
    shortcode: 'thought_balloon',
  },
  {
    id: 'hole',
    shortcode: 'hole',
  },
  {
    id: 'eyeglasses',
    shortcode: 'eyeglasses',
  },
  {
    id: 'dark_sunglasses',
    shortcode: 'dark_sunglasses',
  },
  {
    id: 'necktie',
    shortcode: 'necktie',
  },
  {
    id: 'shirt',
    shortcode: 'shirt',
  },
  {
    id: 'shirt',
    shortcode: 'tshirt',
  },
  {
    id: 'jeans',
    shortcode: 'jeans',
  },
  {
    id: 'scarf',
    shortcode: 'scarf',
  },
  {
    id: 'gloves',
    shortcode: 'gloves',
  },
  {
    id: 'coat',
    shortcode: 'coat',
  },
  {
    id: 'socks',
    shortcode: 'socks',
  },
  {
    id: 'dress',
    shortcode: 'dress',
  },
  {
    id: 'kimono',
    shortcode: 'kimono',
  },
  {
    id: 'bikini',
    shortcode: 'bikini',
  },
  {
    id: 'womans_clothes',
    shortcode: 'womans_clothes',
  },
  {
    id: 'purse',
    shortcode: 'purse',
  },
  {
    id: 'handbag',
    shortcode: 'handbag',
  },
  {
    id: 'pouch',
    shortcode: 'pouch',
  },
  {
    id: 'shopping_bags',
    shortcode: 'shopping_bags',
  },
  {
    id: 'school_satchel',
    shortcode: 'school_satchel',
  },
  {
    id: 'mans_shoe',
    shortcode: 'mans_shoe',
  },
  {
    id: 'mans_shoe',
    shortcode: 'shoe',
  },
  {
    id: 'athletic_shoe',
    shortcode: 'athletic_shoe',
  },
  {
    id: 'high_heel',
    shortcode: 'high_heel',
  },
  {
    id: 'sandal',
    shortcode: 'sandal',
  },
  {
    id: 'boot',
    shortcode: 'boot',
  },
  {
    id: 'crown',
    shortcode: 'crown',
  },
  {
    id: 'womans_hat',
    shortcode: 'womans_hat',
  },
  {
    id: 'tophat',
    shortcode: 'tophat',
  },
  {
    id: 'mortar_board',
    shortcode: 'mortar_board',
  },
  {
    id: 'billed_cap',
    shortcode: 'billed_cap',
  },
  {
    id: 'helmet_with_white_cross',
    shortcode: 'helmet_with_white_cross',
  },
  {
    id: 'prayer_beads',
    shortcode: 'prayer_beads',
  },
  {
    id: 'lipstick',
    shortcode: 'lipstick',
  },
  {
    id: 'ring',
    shortcode: 'ring',
  },
  {
    id: 'gem',
    shortcode: 'gem',
  },
  {
    id: 'monkey_face',
    shortcode: 'monkey_face',
  },
  {
    id: 'monkey',
    shortcode: 'monkey',
  },
  {
    id: 'gorilla',
    shortcode: 'gorilla',
  },
  {
    id: 'dog',
    shortcode: 'dog',
  },
  {
    id: 'dog2',
    shortcode: 'dog2',
  },
  {
    id: 'poodle',
    shortcode: 'poodle',
  },
  {
    id: 'wolf',
    shortcode: 'wolf',
  },
  {
    id: 'fox_face',
    shortcode: 'fox_face',
  },
  {
    id: 'cat',
    shortcode: 'cat',
  },
  {
    id: 'cat2',
    shortcode: 'cat2',
  },
  {
    id: 'lion_face',
    shortcode: 'lion_face',
  },
  {
    id: 'tiger',
    shortcode: 'tiger',
  },
  {
    id: 'tiger2',
    shortcode: 'tiger2',
  },
  {
    id: 'leopard',
    shortcode: 'leopard',
  },
  {
    id: 'horse',
    shortcode: 'horse',
  },
  {
    id: 'racehorse',
    shortcode: 'racehorse',
  },
  {
    id: 'unicorn_face',
    shortcode: 'unicorn_face',
  },
  {
    id: 'zebra_face',
    shortcode: 'zebra_face',
  },
  {
    id: 'deer',
    shortcode: 'deer',
  },
  {
    id: 'cow',
    shortcode: 'cow',
  },
  {
    id: 'ox',
    shortcode: 'ox',
  },
  {
    id: 'water_buffalo',
    shortcode: 'water_buffalo',
  },
  {
    id: 'cow2',
    shortcode: 'cow2',
  },
  {
    id: 'pig',
    shortcode: 'pig',
  },
  {
    id: 'pig2',
    shortcode: 'pig2',
  },
  {
    id: 'boar',
    shortcode: 'boar',
  },
  {
    id: 'pig_nose',
    shortcode: 'pig_nose',
  },
  {
    id: 'ram',
    shortcode: 'ram',
  },
  {
    id: 'sheep',
    shortcode: 'sheep',
  },
  {
    id: 'goat',
    shortcode: 'goat',
  },
  {
    id: 'dromedary_camel',
    shortcode: 'dromedary_camel',
  },
  {
    id: 'camel',
    shortcode: 'camel',
  },
  {
    id: 'giraffe_face',
    shortcode: 'giraffe_face',
  },
  {
    id: 'elephant',
    shortcode: 'elephant',
  },
  {
    id: 'rhinoceros',
    shortcode: 'rhinoceros',
  },
  {
    id: 'mouse',
    shortcode: 'mouse',
  },
  {
    id: 'mouse2',
    shortcode: 'mouse2',
  },
  {
    id: 'rat',
    shortcode: 'rat',
  },
  {
    id: 'hamster',
    shortcode: 'hamster',
  },
  {
    id: 'rabbit',
    shortcode: 'rabbit',
  },
  {
    id: 'rabbit2',
    shortcode: 'rabbit2',
  },
  {
    id: 'chipmunk',
    shortcode: 'chipmunk',
  },
  {
    id: 'hedgehog',
    shortcode: 'hedgehog',
  },
  {
    id: 'bat',
    shortcode: 'bat',
  },
  {
    id: 'bear',
    shortcode: 'bear',
  },
  {
    id: 'koala',
    shortcode: 'koala',
  },
  {
    id: 'panda_face',
    shortcode: 'panda_face',
  },
  {
    id: 'feet',
    shortcode: 'feet',
  },
  {
    id: 'feet',
    shortcode: 'paw_prints',
  },
  {
    id: 'turkey',
    shortcode: 'turkey',
  },
  {
    id: 'chicken',
    shortcode: 'chicken',
  },
  {
    id: 'rooster',
    shortcode: 'rooster',
  },
  {
    id: 'hatching_chick',
    shortcode: 'hatching_chick',
  },
  {
    id: 'baby_chick',
    shortcode: 'baby_chick',
  },
  {
    id: 'hatched_chick',
    shortcode: 'hatched_chick',
  },
  {
    id: 'bird',
    shortcode: 'bird',
  },
  {
    id: 'penguin',
    shortcode: 'penguin',
  },
  {
    id: 'dove_of_peace',
    shortcode: 'dove_of_peace',
  },
  {
    id: 'eagle',
    shortcode: 'eagle',
  },
  {
    id: 'duck',
    shortcode: 'duck',
  },
  {
    id: 'owl',
    shortcode: 'owl',
  },
  {
    id: 'frog',
    shortcode: 'frog',
  },
  {
    id: 'crocodile',
    shortcode: 'crocodile',
  },
  {
    id: 'turtle',
    shortcode: 'turtle',
  },
  {
    id: 'lizard',
    shortcode: 'lizard',
  },
  {
    id: 'snake',
    shortcode: 'snake',
  },
  {
    id: 'dragon_face',
    shortcode: 'dragon_face',
  },
  {
    id: 'dragon',
    shortcode: 'dragon',
  },
  {
    id: 'sauropod',
    shortcode: 'sauropod',
  },
  {
    id: 't-rex',
    shortcode: 't-rex',
  },
  {
    id: 'whale',
    shortcode: 'whale',
  },
  {
    id: 'whale2',
    shortcode: 'whale2',
  },
  {
    id: 'dolphin',
    shortcode: 'dolphin',
  },
  {
    id: 'dolphin',
    shortcode: 'flipper',
  },
  {
    id: 'fish',
    shortcode: 'fish',
  },
  {
    id: 'tropical_fish',
    shortcode: 'tropical_fish',
  },
  {
    id: 'blowfish',
    shortcode: 'blowfish',
  },
  {
    id: 'shark',
    shortcode: 'shark',
  },
  {
    id: 'octopus',
    shortcode: 'octopus',
  },
  {
    id: 'shell',
    shortcode: 'shell',
  },
  {
    id: 'crab',
    shortcode: 'crab',
  },
  {
    id: 'shrimp',
    shortcode: 'shrimp',
  },
  {
    id: 'squid',
    shortcode: 'squid',
  },
  {
    id: 'snail',
    shortcode: 'snail',
  },
  {
    id: 'butterfly',
    shortcode: 'butterfly',
  },
  {
    id: 'bug',
    shortcode: 'bug',
  },
  {
    id: 'ant',
    shortcode: 'ant',
  },
  {
    id: 'bee',
    shortcode: 'bee',
  },
  {
    id: 'bee',
    shortcode: 'honeybee',
  },
  {
    id: 'beetle',
    shortcode: 'beetle',
  },
  {
    id: 'cricket',
    shortcode: 'cricket',
  },
  {
    id: 'spider',
    shortcode: 'spider',
  },
  {
    id: 'spider_web',
    shortcode: 'spider_web',
  },
  {
    id: 'scorpion',
    shortcode: 'scorpion',
  },
  {
    id: 'bouquet',
    shortcode: 'bouquet',
  },
  {
    id: 'cherry_blossom',
    shortcode: 'cherry_blossom',
  },
  {
    id: 'white_flower',
    shortcode: 'white_flower',
  },
  {
    id: 'rosette',
    shortcode: 'rosette',
  },
  {
    id: 'rose',
    shortcode: 'rose',
  },
  {
    id: 'wilted_flower',
    shortcode: 'wilted_flower',
  },
  {
    id: 'hibiscus',
    shortcode: 'hibiscus',
  },
  {
    id: 'sunflower',
    shortcode: 'sunflower',
  },
  {
    id: 'blossom',
    shortcode: 'blossom',
  },
  {
    id: 'tulip',
    shortcode: 'tulip',
  },
  {
    id: 'seedling',
    shortcode: 'seedling',
  },
  {
    id: 'evergreen_tree',
    shortcode: 'evergreen_tree',
  },
  {
    id: 'deciduous_tree',
    shortcode: 'deciduous_tree',
  },
  {
    id: 'palm_tree',
    shortcode: 'palm_tree',
  },
  {
    id: 'cactus',
    shortcode: 'cactus',
  },
  {
    id: 'ear_of_rice',
    shortcode: 'ear_of_rice',
  },
  {
    id: 'herb',
    shortcode: 'herb',
  },
  {
    id: 'shamrock',
    shortcode: 'shamrock',
  },
  {
    id: 'four_leaf_clover',
    shortcode: 'four_leaf_clover',
  },
  {
    id: 'maple_leaf',
    shortcode: 'maple_leaf',
  },
  {
    id: 'fallen_leaf',
    shortcode: 'fallen_leaf',
  },
  {
    id: 'leaves',
    shortcode: 'leaves',
  },
  {
    id: 'grapes',
    shortcode: 'grapes',
  },
  {
    id: 'melon',
    shortcode: 'melon',
  },
  {
    id: 'watermelon',
    shortcode: 'watermelon',
  },
  {
    id: 'tangerine',
    shortcode: 'tangerine',
  },
  {
    id: 'lemon',
    shortcode: 'lemon',
  },
  {
    id: 'banana',
    shortcode: 'banana',
  },
  {
    id: 'pineapple',
    shortcode: 'pineapple',
  },
  {
    id: 'apple',
    shortcode: 'apple',
  },
  {
    id: 'green_apple',
    shortcode: 'green_apple',
  },
  {
    id: 'pear',
    shortcode: 'pear',
  },
  {
    id: 'peach',
    shortcode: 'peach',
  },
  {
    id: 'cherries',
    shortcode: 'cherries',
  },
  {
    id: 'strawberry',
    shortcode: 'strawberry',
  },
  {
    id: 'kiwifruit',
    shortcode: 'kiwifruit',
  },
  {
    id: 'tomato',
    shortcode: 'tomato',
  },
  {
    id: 'coconut',
    shortcode: 'coconut',
  },
  {
    id: 'avocado',
    shortcode: 'avocado',
  },
  {
    id: 'eggplant',
    shortcode: 'eggplant',
  },
  {
    id: 'potato',
    shortcode: 'potato',
  },
  {
    id: 'carrot',
    shortcode: 'carrot',
  },
  {
    id: 'corn',
    shortcode: 'corn',
  },
  {
    id: 'hot_pepper',
    shortcode: 'hot_pepper',
  },
  {
    id: 'cucumber',
    shortcode: 'cucumber',
  },
  {
    id: 'broccoli',
    shortcode: 'broccoli',
  },
  {
    id: 'mushroom',
    shortcode: 'mushroom',
  },
  {
    id: 'peanuts',
    shortcode: 'peanuts',
  },
  {
    id: 'chestnut',
    shortcode: 'chestnut',
  },
  {
    id: 'bread',
    shortcode: 'bread',
  },
  {
    id: 'croissant',
    shortcode: 'croissant',
  },
  {
    id: 'baguette_bread',
    shortcode: 'baguette_bread',
  },
  {
    id: 'pretzel',
    shortcode: 'pretzel',
  },
  {
    id: 'pancakes',
    shortcode: 'pancakes',
  },
  {
    id: 'cheese_wedge',
    shortcode: 'cheese_wedge',
  },
  {
    id: 'meat_on_bone',
    shortcode: 'meat_on_bone',
  },
  {
    id: 'poultry_leg',
    shortcode: 'poultry_leg',
  },
  {
    id: 'cut_of_meat',
    shortcode: 'cut_of_meat',
  },
  {
    id: 'bacon',
    shortcode: 'bacon',
  },
  {
    id: 'hamburger',
    shortcode: 'hamburger',
  },
  {
    id: 'fries',
    shortcode: 'fries',
  },
  {
    id: 'pizza',
    shortcode: 'pizza',
  },
  {
    id: 'hotdog',
    shortcode: 'hotdog',
  },
  {
    id: 'sandwich',
    shortcode: 'sandwich',
  },
  {
    id: 'taco',
    shortcode: 'taco',
  },
  {
    id: 'burrito',
    shortcode: 'burrito',
  },
  {
    id: 'stuffed_flatbread',
    shortcode: 'stuffed_flatbread',
  },
  {
    id: 'egg',
    shortcode: 'egg',
  },
  {
    id: 'fried_egg',
    shortcode: 'fried_egg',
  },
  {
    id: 'fried_egg',
    shortcode: 'cooking',
  },
  {
    id: 'shallow_pan_of_food',
    shortcode: 'shallow_pan_of_food',
  },
  {
    id: 'stew',
    shortcode: 'stew',
  },
  {
    id: 'bowl_with_spoon',
    shortcode: 'bowl_with_spoon',
  },
  {
    id: 'green_salad',
    shortcode: 'green_salad',
  },
  {
    id: 'popcorn',
    shortcode: 'popcorn',
  },
  {
    id: 'canned_food',
    shortcode: 'canned_food',
  },
  {
    id: 'bento',
    shortcode: 'bento',
  },
  {
    id: 'rice_cracker',
    shortcode: 'rice_cracker',
  },
  {
    id: 'rice_ball',
    shortcode: 'rice_ball',
  },
  {
    id: 'rice',
    shortcode: 'rice',
  },
  {
    id: 'curry',
    shortcode: 'curry',
  },
  {
    id: 'ramen',
    shortcode: 'ramen',
  },
  {
    id: 'spaghetti',
    shortcode: 'spaghetti',
  },
  {
    id: 'sweet_potato',
    shortcode: 'sweet_potato',
  },
  {
    id: 'oden',
    shortcode: 'oden',
  },
  {
    id: 'sushi',
    shortcode: 'sushi',
  },
  {
    id: 'fried_shrimp',
    shortcode: 'fried_shrimp',
  },
  {
    id: 'fish_cake',
    shortcode: 'fish_cake',
  },
  {
    id: 'dango',
    shortcode: 'dango',
  },
  {
    id: 'dumpling',
    shortcode: 'dumpling',
  },
  {
    id: 'fortune_cookie',
    shortcode: 'fortune_cookie',
  },
  {
    id: 'takeout_box',
    shortcode: 'takeout_box',
  },
  {
    id: 'icecream',
    shortcode: 'icecream',
  },
  {
    id: 'shaved_ice',
    shortcode: 'shaved_ice',
  },
  {
    id: 'ice_cream',
    shortcode: 'ice_cream',
  },
  {
    id: 'doughnut',
    shortcode: 'doughnut',
  },
  {
    id: 'cookie',
    shortcode: 'cookie',
  },
  {
    id: 'birthday',
    shortcode: 'birthday',
  },
  {
    id: 'cake',
    shortcode: 'cake',
  },
  {
    id: 'pie',
    shortcode: 'pie',
  },
  {
    id: 'chocolate_bar',
    shortcode: 'chocolate_bar',
  },
  {
    id: 'candy',
    shortcode: 'candy',
  },
  {
    id: 'lollipop',
    shortcode: 'lollipop',
  },
  {
    id: 'custard',
    shortcode: 'custard',
  },
  {
    id: 'honey_pot',
    shortcode: 'honey_pot',
  },
  {
    id: 'baby_bottle',
    shortcode: 'baby_bottle',
  },
  {
    id: 'glass_of_milk',
    shortcode: 'glass_of_milk',
  },
  {
    id: 'coffee',
    shortcode: 'coffee',
  },
  {
    id: 'tea',
    shortcode: 'tea',
  },
  {
    id: 'sake',
    shortcode: 'sake',
  },
  {
    id: 'champagne',
    shortcode: 'champagne',
  },
  {
    id: 'wine_glass',
    shortcode: 'wine_glass',
  },
  {
    id: 'cocktail',
    shortcode: 'cocktail',
  },
  {
    id: 'tropical_drink',
    shortcode: 'tropical_drink',
  },
  {
    id: 'beer',
    shortcode: 'beer',
  },
  {
    id: 'beers',
    shortcode: 'beers',
  },
  {
    id: 'clinking_glasses',
    shortcode: 'clinking_glasses',
  },
  {
    id: 'tumbler_glass',
    shortcode: 'tumbler_glass',
  },
  {
    id: 'cup_with_straw',
    shortcode: 'cup_with_straw',
  },
  {
    id: 'chopsticks',
    shortcode: 'chopsticks',
  },
  {
    id: 'knife_fork_plate',
    shortcode: 'knife_fork_plate',
  },
  {
    id: 'fork_and_knife',
    shortcode: 'fork_and_knife',
  },
  {
    id: 'spoon',
    shortcode: 'spoon',
  },
  {
    id: 'hocho',
    shortcode: 'hocho',
  },
  {
    id: 'hocho',
    shortcode: 'knife',
  },
  {
    id: 'amphora',
    shortcode: 'amphora',
  },
  {
    id: 'jack_o_lantern',
    shortcode: 'jack_o_lantern',
  },
  {
    id: 'christmas_tree',
    shortcode: 'christmas_tree',
  },
  {
    id: 'fireworks',
    shortcode: 'fireworks',
  },
  {
    id: 'sparkler',
    shortcode: 'sparkler',
  },
  {
    id: 'sparkles',
    shortcode: 'sparkles',
  },
  {
    id: 'balloon',
    shortcode: 'balloon',
  },
  {
    id: 'tada',
    shortcode: 'tada',
  },
  {
    id: 'confetti_ball',
    shortcode: 'confetti_ball',
  },
  {
    id: 'tanabata_tree',
    shortcode: 'tanabata_tree',
  },
  {
    id: 'bamboo',
    shortcode: 'bamboo',
  },
  {
    id: 'dolls',
    shortcode: 'dolls',
  },
  {
    id: 'flags',
    shortcode: 'flags',
  },
  {
    id: 'wind_chime',
    shortcode: 'wind_chime',
  },
  {
    id: 'rice_scene',
    shortcode: 'rice_scene',
  },
  {
    id: 'ribbon',
    shortcode: 'ribbon',
  },
  {
    id: 'gift',
    shortcode: 'gift',
  },
  {
    id: 'reminder_ribbon',
    shortcode: 'reminder_ribbon',
  },
  {
    id: 'admission_tickets',
    shortcode: 'admission_tickets',
  },
  {
    id: 'ticket',
    shortcode: 'ticket',
  },
  {
    id: 'medal',
    shortcode: 'medal',
  },
  {
    id: 'trophy',
    shortcode: 'trophy',
  },
  {
    id: 'sports_medal',
    shortcode: 'sports_medal',
  },
  {
    id: 'first_place_medal',
    shortcode: 'first_place_medal',
  },
  {
    id: 'second_place_medal',
    shortcode: 'second_place_medal',
  },
  {
    id: 'third_place_medal',
    shortcode: 'third_place_medal',
  },
  {
    id: 'soccer',
    shortcode: 'soccer',
  },
  {
    id: 'baseball',
    shortcode: 'baseball',
  },
  {
    id: 'basketball',
    shortcode: 'basketball',
  },
  {
    id: 'volleyball',
    shortcode: 'volleyball',
  },
  {
    id: 'football',
    shortcode: 'football',
  },
  {
    id: 'rugby_football',
    shortcode: 'rugby_football',
  },
  {
    id: 'tennis',
    shortcode: 'tennis',
  },
  {
    id: '8ball',
    shortcode: '8ball',
  },
  {
    id: 'bowling',
    shortcode: 'bowling',
  },
  {
    id: 'cricket_bat_and_ball',
    shortcode: 'cricket_bat_and_ball',
  },
  {
    id: 'field_hockey_stick_and_ball',
    shortcode: 'field_hockey_stick_and_ball',
  },
  {
    id: 'ice_hockey_stick_and_puck',
    shortcode: 'ice_hockey_stick_and_puck',
  },
  {
    id: 'table_tennis_paddle_and_ball',
    shortcode: 'table_tennis_paddle_and_ball',
  },
  {
    id: 'badminton_racquet_and_shuttlecock',
    shortcode: 'badminton_racquet_and_shuttlecock',
  },
  {
    id: 'boxing_glove',
    shortcode: 'boxing_glove',
  },
  {
    id: 'martial_arts_uniform',
    shortcode: 'martial_arts_uniform',
  },
  {
    id: 'goal_net',
    shortcode: 'goal_net',
  },
  {
    id: 'dart',
    shortcode: 'dart',
  },
  {
    id: 'golf',
    shortcode: 'golf',
  },
  {
    id: 'ice_skate',
    shortcode: 'ice_skate',
  },
  {
    id: 'fishing_pole_and_fish',
    shortcode: 'fishing_pole_and_fish',
  },
  {
    id: 'running_shirt_with_sash',
    shortcode: 'running_shirt_with_sash',
  },
  {
    id: 'ski',
    shortcode: 'ski',
  },
  {
    id: 'sled',
    shortcode: 'sled',
  },
  {
    id: 'curling_stone',
    shortcode: 'curling_stone',
  },
  {
    id: 'video_game',
    shortcode: 'video_game',
  },
  {
    id: 'joystick',
    shortcode: 'joystick',
  },
  {
    id: 'game_die',
    shortcode: 'game_die',
  },
  {
    id: 'spades',
    shortcode: 'spades',
  },
  {
    id: 'hearts',
    shortcode: 'hearts',
  },
  {
    id: 'diamonds',
    shortcode: 'diamonds',
  },
  {
    id: 'clubs',
    shortcode: 'clubs',
  },
  {
    id: 'black_joker',
    shortcode: 'black_joker',
  },
  {
    id: 'mahjong',
    shortcode: 'mahjong',
  },
  {
    id: 'flower_playing_cards',
    shortcode: 'flower_playing_cards',
  },
  {
    id: 'earth_africa',
    shortcode: 'earth_africa',
  },
  {
    id: 'earth_americas',
    shortcode: 'earth_americas',
  },
  {
    id: 'earth_asia',
    shortcode: 'earth_asia',
  },
  {
    id: 'globe_with_meridians',
    shortcode: 'globe_with_meridians',
  },
  {
    id: 'world_map',
    shortcode: 'world_map',
  },
  {
    id: 'japan',
    shortcode: 'japan',
  },
  {
    id: 'snow_capped_mountain',
    shortcode: 'snow_capped_mountain',
  },
  {
    id: 'mountain',
    shortcode: 'mountain',
  },
  {
    id: 'volcano',
    shortcode: 'volcano',
  },
  {
    id: 'mount_fuji',
    shortcode: 'mount_fuji',
  },
  {
    id: 'camping',
    shortcode: 'camping',
  },
  {
    id: 'beach_with_umbrella',
    shortcode: 'beach_with_umbrella',
  },
  {
    id: 'desert',
    shortcode: 'desert',
  },
  {
    id: 'desert_island',
    shortcode: 'desert_island',
  },
  {
    id: 'national_park',
    shortcode: 'national_park',
  },
  {
    id: 'stadium',
    shortcode: 'stadium',
  },
  {
    id: 'classical_building',
    shortcode: 'classical_building',
  },
  {
    id: 'building_construction',
    shortcode: 'building_construction',
  },
  {
    id: 'house_buildings',
    shortcode: 'house_buildings',
  },
  {
    id: 'cityscape',
    shortcode: 'cityscape',
  },
  {
    id: 'derelict_house_building',
    shortcode: 'derelict_house_building',
  },
  {
    id: 'house',
    shortcode: 'house',
  },
  {
    id: 'house_with_garden',
    shortcode: 'house_with_garden',
  },
  {
    id: 'office',
    shortcode: 'office',
  },
  {
    id: 'post_office',
    shortcode: 'post_office',
  },
  {
    id: 'european_post_office',
    shortcode: 'european_post_office',
  },
  {
    id: 'hospital',
    shortcode: 'hospital',
  },
  {
    id: 'bank',
    shortcode: 'bank',
  },
  {
    id: 'hotel',
    shortcode: 'hotel',
  },
  {
    id: 'love_hotel',
    shortcode: 'love_hotel',
  },
  {
    id: 'convenience_store',
    shortcode: 'convenience_store',
  },
  {
    id: 'school',
    shortcode: 'school',
  },
  {
    id: 'department_store',
    shortcode: 'department_store',
  },
  {
    id: 'factory',
    shortcode: 'factory',
  },
  {
    id: 'japanese_castle',
    shortcode: 'japanese_castle',
  },
  {
    id: 'european_castle',
    shortcode: 'european_castle',
  },
  {
    id: 'wedding',
    shortcode: 'wedding',
  },
  {
    id: 'tokyo_tower',
    shortcode: 'tokyo_tower',
  },
  {
    id: 'statue_of_liberty',
    shortcode: 'statue_of_liberty',
  },
  {
    id: 'church',
    shortcode: 'church',
  },
  {
    id: 'mosque',
    shortcode: 'mosque',
  },
  {
    id: 'synagogue',
    shortcode: 'synagogue',
  },
  {
    id: 'shinto_shrine',
    shortcode: 'shinto_shrine',
  },
  {
    id: 'kaaba',
    shortcode: 'kaaba',
  },
  {
    id: 'fountain',
    shortcode: 'fountain',
  },
  {
    id: 'tent',
    shortcode: 'tent',
  },
  {
    id: 'foggy',
    shortcode: 'foggy',
  },
  {
    id: 'night_with_stars',
    shortcode: 'night_with_stars',
  },
  {
    id: 'sunrise_over_mountains',
    shortcode: 'sunrise_over_mountains',
  },
  {
    id: 'sunrise',
    shortcode: 'sunrise',
  },
  {
    id: 'city_sunset',
    shortcode: 'city_sunset',
  },
  {
    id: 'city_sunrise',
    shortcode: 'city_sunrise',
  },
  {
    id: 'bridge_at_night',
    shortcode: 'bridge_at_night',
  },
  {
    id: 'hotsprings',
    shortcode: 'hotsprings',
  },
  {
    id: 'milky_way',
    shortcode: 'milky_way',
  },
  {
    id: 'carousel_horse',
    shortcode: 'carousel_horse',
  },
  {
    id: 'ferris_wheel',
    shortcode: 'ferris_wheel',
  },
  {
    id: 'roller_coaster',
    shortcode: 'roller_coaster',
  },
  {
    id: 'barber',
    shortcode: 'barber',
  },
  {
    id: 'circus_tent',
    shortcode: 'circus_tent',
  },
  {
    id: 'performing_arts',
    shortcode: 'performing_arts',
  },
  {
    id: 'frame_with_picture',
    shortcode: 'frame_with_picture',
  },
  {
    id: 'art',
    shortcode: 'art',
  },
  {
    id: 'slot_machine',
    shortcode: 'slot_machine',
  },
  {
    id: 'steam_locomotive',
    shortcode: 'steam_locomotive',
  },
  {
    id: 'railway_car',
    shortcode: 'railway_car',
  },
  {
    id: 'bullettrain_side',
    shortcode: 'bullettrain_side',
  },
  {
    id: 'bullettrain_front',
    shortcode: 'bullettrain_front',
  },
  {
    id: 'train2',
    shortcode: 'train2',
  },
  {
    id: 'metro',
    shortcode: 'metro',
  },
  {
    id: 'light_rail',
    shortcode: 'light_rail',
  },
  {
    id: 'station',
    shortcode: 'station',
  },
  {
    id: 'tram',
    shortcode: 'tram',
  },
  {
    id: 'monorail',
    shortcode: 'monorail',
  },
  {
    id: 'mountain_railway',
    shortcode: 'mountain_railway',
  },
  {
    id: 'train',
    shortcode: 'train',
  },
  {
    id: 'bus',
    shortcode: 'bus',
  },
  {
    id: 'oncoming_bus',
    shortcode: 'oncoming_bus',
  },
  {
    id: 'trolleybus',
    shortcode: 'trolleybus',
  },
  {
    id: 'minibus',
    shortcode: 'minibus',
  },
  {
    id: 'ambulance',
    shortcode: 'ambulance',
  },
  {
    id: 'fire_engine',
    shortcode: 'fire_engine',
  },
  {
    id: 'police_car',
    shortcode: 'police_car',
  },
  {
    id: 'oncoming_police_car',
    shortcode: 'oncoming_police_car',
  },
  {
    id: 'taxi',
    shortcode: 'taxi',
  },
  {
    id: 'oncoming_taxi',
    shortcode: 'oncoming_taxi',
  },
  {
    id: 'car',
    shortcode: 'car',
  },
  {
    id: 'car',
    shortcode: 'red_car',
  },
  {
    id: 'oncoming_automobile',
    shortcode: 'oncoming_automobile',
  },
  {
    id: 'blue_car',
    shortcode: 'blue_car',
  },
  {
    id: 'truck',
    shortcode: 'truck',
  },
  {
    id: 'articulated_lorry',
    shortcode: 'articulated_lorry',
  },
  {
    id: 'tractor',
    shortcode: 'tractor',
  },
  {
    id: 'bike',
    shortcode: 'bike',
  },
  {
    id: 'scooter',
    shortcode: 'scooter',
  },
  {
    id: 'motor_scooter',
    shortcode: 'motor_scooter',
  },
  {
    id: 'busstop',
    shortcode: 'busstop',
  },
  {
    id: 'motorway',
    shortcode: 'motorway',
  },
  {
    id: 'railway_track',
    shortcode: 'railway_track',
  },
  {
    id: 'fuelpump',
    shortcode: 'fuelpump',
  },
  {
    id: 'rotating_light',
    shortcode: 'rotating_light',
  },
  {
    id: 'traffic_light',
    shortcode: 'traffic_light',
  },
  {
    id: 'vertical_traffic_light',
    shortcode: 'vertical_traffic_light',
  },
  {
    id: 'construction',
    shortcode: 'construction',
  },
  {
    id: 'octagonal_sign',
    shortcode: 'octagonal_sign',
  },
  {
    id: 'anchor',
    shortcode: 'anchor',
  },
  {
    id: 'boat',
    shortcode: 'boat',
  },
  {
    id: 'boat',
    shortcode: 'sailboat',
  },
  {
    id: 'canoe',
    shortcode: 'canoe',
  },
  {
    id: 'speedboat',
    shortcode: 'speedboat',
  },
  {
    id: 'passenger_ship',
    shortcode: 'passenger_ship',
  },
  {
    id: 'ferry',
    shortcode: 'ferry',
  },
  {
    id: 'motor_boat',
    shortcode: 'motor_boat',
  },
  {
    id: 'ship',
    shortcode: 'ship',
  },
  {
    id: 'airplane',
    shortcode: 'airplane',
  },
  {
    id: 'small_airplane',
    shortcode: 'small_airplane',
  },
  {
    id: 'airplane_departure',
    shortcode: 'airplane_departure',
  },
  {
    id: 'airplane_arriving',
    shortcode: 'airplane_arriving',
  },
  {
    id: 'seat',
    shortcode: 'seat',
  },
  {
    id: 'helicopter',
    shortcode: 'helicopter',
  },
  {
    id: 'suspension_railway',
    shortcode: 'suspension_railway',
  },
  {
    id: 'mountain_cableway',
    shortcode: 'mountain_cableway',
  },
  {
    id: 'aerial_tramway',
    shortcode: 'aerial_tramway',
  },
  {
    id: 'satellite',
    shortcode: 'satellite',
  },
  {
    id: 'rocket',
    shortcode: 'rocket',
  },
  {
    id: 'flying_saucer',
    shortcode: 'flying_saucer',
  },
  {
    id: 'bellhop_bell',
    shortcode: 'bellhop_bell',
  },
  {
    id: 'door',
    shortcode: 'door',
  },
  {
    id: 'bed',
    shortcode: 'bed',
  },
  {
    id: 'couch_and_lamp',
    shortcode: 'couch_and_lamp',
  },
  {
    id: 'toilet',
    shortcode: 'toilet',
  },
  {
    id: 'shower',
    shortcode: 'shower',
  },
  {
    id: 'bathtub',
    shortcode: 'bathtub',
  },
  {
    id: 'hourglass',
    shortcode: 'hourglass',
  },
  {
    id: 'hourglass_flowing_sand',
    shortcode: 'hourglass_flowing_sand',
  },
  {
    id: 'watch',
    shortcode: 'watch',
  },
  {
    id: 'alarm_clock',
    shortcode: 'alarm_clock',
  },
  {
    id: 'stopwatch',
    shortcode: 'stopwatch',
  },
  {
    id: 'timer_clock',
    shortcode: 'timer_clock',
  },
  {
    id: 'mantelpiece_clock',
    shortcode: 'mantelpiece_clock',
  },
  {
    id: 'clock12',
    shortcode: 'clock12',
  },
  {
    id: 'clock1230',
    shortcode: 'clock1230',
  },
  {
    id: 'clock1',
    shortcode: 'clock1',
  },
  {
    id: 'clock130',
    shortcode: 'clock130',
  },
  {
    id: 'clock2',
    shortcode: 'clock2',
  },
  {
    id: 'clock230',
    shortcode: 'clock230',
  },
  {
    id: 'clock3',
    shortcode: 'clock3',
  },
  {
    id: 'clock330',
    shortcode: 'clock330',
  },
  {
    id: 'clock4',
    shortcode: 'clock4',
  },
  {
    id: 'clock430',
    shortcode: 'clock430',
  },
  {
    id: 'clock5',
    shortcode: 'clock5',
  },
  {
    id: 'clock530',
    shortcode: 'clock530',
  },
  {
    id: 'clock6',
    shortcode: 'clock6',
  },
  {
    id: 'clock630',
    shortcode: 'clock630',
  },
  {
    id: 'clock7',
    shortcode: 'clock7',
  },
  {
    id: 'clock730',
    shortcode: 'clock730',
  },
  {
    id: 'clock8',
    shortcode: 'clock8',
  },
  {
    id: 'clock830',
    shortcode: 'clock830',
  },
  {
    id: 'clock9',
    shortcode: 'clock9',
  },
  {
    id: 'clock930',
    shortcode: 'clock930',
  },
  {
    id: 'clock10',
    shortcode: 'clock10',
  },
  {
    id: 'clock1030',
    shortcode: 'clock1030',
  },
  {
    id: 'clock11',
    shortcode: 'clock11',
  },
  {
    id: 'clock1130',
    shortcode: 'clock1130',
  },
  {
    id: 'new_moon',
    shortcode: 'new_moon',
  },
  {
    id: 'waxing_crescent_moon',
    shortcode: 'waxing_crescent_moon',
  },
  {
    id: 'first_quarter_moon',
    shortcode: 'first_quarter_moon',
  },
  {
    id: 'moon',
    shortcode: 'moon',
  },
  {
    id: 'moon',
    shortcode: 'waxing_gibbous_moon',
  },
  {
    id: 'full_moon',
    shortcode: 'full_moon',
  },
  {
    id: 'waning_gibbous_moon',
    shortcode: 'waning_gibbous_moon',
  },
  {
    id: 'last_quarter_moon',
    shortcode: 'last_quarter_moon',
  },
  {
    id: 'waning_crescent_moon',
    shortcode: 'waning_crescent_moon',
  },
  {
    id: 'crescent_moon',
    shortcode: 'crescent_moon',
  },
  {
    id: 'new_moon_with_face',
    shortcode: 'new_moon_with_face',
  },
  {
    id: 'first_quarter_moon_with_face',
    shortcode: 'first_quarter_moon_with_face',
  },
  {
    id: 'last_quarter_moon_with_face',
    shortcode: 'last_quarter_moon_with_face',
  },
  {
    id: 'thermometer',
    shortcode: 'thermometer',
  },
  {
    id: 'sunny',
    shortcode: 'sunny',
  },
  {
    id: 'full_moon_with_face',
    shortcode: 'full_moon_with_face',
  },
  {
    id: 'sun_with_face',
    shortcode: 'sun_with_face',
  },
  {
    id: 'star',
    shortcode: 'star',
  },
  {
    id: 'star2',
    shortcode: 'star2',
  },
  {
    id: 'stars',
    shortcode: 'stars',
  },
  {
    id: 'cloud',
    shortcode: 'cloud',
  },
  {
    id: 'partly_sunny',
    shortcode: 'partly_sunny',
  },
  {
    id: 'thunder_cloud_and_rain',
    shortcode: 'thunder_cloud_and_rain',
  },
  {
    id: 'mostly_sunny',
    shortcode: 'mostly_sunny',
  },
  {
    id: 'mostly_sunny',
    shortcode: 'sun_small_cloud',
  },
  {
    id: 'barely_sunny',
    shortcode: 'barely_sunny',
  },
  {
    id: 'barely_sunny',
    shortcode: 'sun_behind_cloud',
  },
  {
    id: 'partly_sunny_rain',
    shortcode: 'partly_sunny_rain',
  },
  {
    id: 'partly_sunny_rain',
    shortcode: 'sun_behind_rain_cloud',
  },
  {
    id: 'rain_cloud',
    shortcode: 'rain_cloud',
  },
  {
    id: 'snow_cloud',
    shortcode: 'snow_cloud',
  },
  {
    id: 'lightning',
    shortcode: 'lightning',
  },
  {
    id: 'lightning',
    shortcode: 'lightning_cloud',
  },
  {
    id: 'tornado',
    shortcode: 'tornado',
  },
  {
    id: 'tornado',
    shortcode: 'tornado_cloud',
  },
  {
    id: 'fog',
    shortcode: 'fog',
  },
  {
    id: 'wind_blowing_face',
    shortcode: 'wind_blowing_face',
  },
  {
    id: 'cyclone',
    shortcode: 'cyclone',
  },
  {
    id: 'rainbow',
    shortcode: 'rainbow',
  },
  {
    id: 'closed_umbrella',
    shortcode: 'closed_umbrella',
  },
  {
    id: 'umbrella',
    shortcode: 'umbrella',
  },
  {
    id: 'umbrella_with_rain_drops',
    shortcode: 'umbrella_with_rain_drops',
  },
  {
    id: 'umbrella_on_ground',
    shortcode: 'umbrella_on_ground',
  },
  {
    id: 'zap',
    shortcode: 'zap',
  },
  {
    id: 'snowflake',
    shortcode: 'snowflake',
  },
  {
    id: 'snowman',
    shortcode: 'snowman',
  },
  {
    id: 'snowman_without_snow',
    shortcode: 'snowman_without_snow',
  },
  {
    id: 'comet',
    shortcode: 'comet',
  },
  {
    id: 'fire',
    shortcode: 'fire',
  },
  {
    id: 'droplet',
    shortcode: 'droplet',
  },
  {
    id: 'ocean',
    shortcode: 'ocean',
  },
  {
    id: 'mute',
    shortcode: 'mute',
  },
  {
    id: 'speaker',
    shortcode: 'speaker',
  },
  {
    id: 'sound',
    shortcode: 'sound',
  },
  {
    id: 'loud_sound',
    shortcode: 'loud_sound',
  },
  {
    id: 'loudspeaker',
    shortcode: 'loudspeaker',
  },
  {
    id: 'mega',
    shortcode: 'mega',
  },
  {
    id: 'postal_horn',
    shortcode: 'postal_horn',
  },
  {
    id: 'bell',
    shortcode: 'bell',
  },
  {
    id: 'no_bell',
    shortcode: 'no_bell',
  },
  {
    id: 'musical_score',
    shortcode: 'musical_score',
  },
  {
    id: 'musical_note',
    shortcode: 'musical_note',
  },
  {
    id: 'notes',
    shortcode: 'notes',
  },
  {
    id: 'studio_microphone',
    shortcode: 'studio_microphone',
  },
  {
    id: 'level_slider',
    shortcode: 'level_slider',
  },
  {
    id: 'control_knobs',
    shortcode: 'control_knobs',
  },
  {
    id: 'microphone',
    shortcode: 'microphone',
  },
  {
    id: 'headphones',
    shortcode: 'headphones',
  },
  {
    id: 'radio',
    shortcode: 'radio',
  },
  {
    id: 'saxophone',
    shortcode: 'saxophone',
  },
  {
    id: 'guitar',
    shortcode: 'guitar',
  },
  {
    id: 'musical_keyboard',
    shortcode: 'musical_keyboard',
  },
  {
    id: 'trumpet',
    shortcode: 'trumpet',
  },
  {
    id: 'violin',
    shortcode: 'violin',
  },
  {
    id: 'drum_with_drumsticks',
    shortcode: 'drum_with_drumsticks',
  },
  {
    id: 'iphone',
    shortcode: 'iphone',
  },
  {
    id: 'calling',
    shortcode: 'calling',
  },
  {
    id: 'phone',
    shortcode: 'phone',
  },
  {
    id: 'phone',
    shortcode: 'telephone',
  },
  {
    id: 'telephone_receiver',
    shortcode: 'telephone_receiver',
  },
  {
    id: 'pager',
    shortcode: 'pager',
  },
  {
    id: 'fax',
    shortcode: 'fax',
  },
  {
    id: 'battery',
    shortcode: 'battery',
  },
  {
    id: 'electric_plug',
    shortcode: 'electric_plug',
  },
  {
    id: 'computer',
    shortcode: 'computer',
  },
  {
    id: 'desktop_computer',
    shortcode: 'desktop_computer',
  },
  {
    id: 'printer',
    shortcode: 'printer',
  },
  {
    id: 'keyboard',
    shortcode: 'keyboard',
  },
  {
    id: 'three_button_mouse',
    shortcode: 'three_button_mouse',
  },
  {
    id: 'trackball',
    shortcode: 'trackball',
  },
  {
    id: 'minidisc',
    shortcode: 'minidisc',
  },
  {
    id: 'floppy_disk',
    shortcode: 'floppy_disk',
  },
  {
    id: 'cd',
    shortcode: 'cd',
  },
  {
    id: 'dvd',
    shortcode: 'dvd',
  },
  {
    id: 'movie_camera',
    shortcode: 'movie_camera',
  },
  {
    id: 'film_frames',
    shortcode: 'film_frames',
  },
  {
    id: 'film_projector',
    shortcode: 'film_projector',
  },
  {
    id: 'clapper',
    shortcode: 'clapper',
  },
  {
    id: 'tv',
    shortcode: 'tv',
  },
  {
    id: 'camera',
    shortcode: 'camera',
  },
  {
    id: 'camera_with_flash',
    shortcode: 'camera_with_flash',
  },
  {
    id: 'video_camera',
    shortcode: 'video_camera',
  },
  {
    id: 'vhs',
    shortcode: 'vhs',
  },
  {
    id: 'mag',
    shortcode: 'mag',
  },
  {
    id: 'mag_right',
    shortcode: 'mag_right',
  },
  {
    id: 'microscope',
    shortcode: 'microscope',
  },
  {
    id: 'telescope',
    shortcode: 'telescope',
  },
  {
    id: 'satellite_antenna',
    shortcode: 'satellite_antenna',
  },
  {
    id: 'candle',
    shortcode: 'candle',
  },
  {
    id: 'bulb',
    shortcode: 'bulb',
  },
  {
    id: 'flashlight',
    shortcode: 'flashlight',
  },
  {
    id: 'izakaya_lantern',
    shortcode: 'izakaya_lantern',
  },
  {
    id: 'izakaya_lantern',
    shortcode: 'lantern',
  },
  {
    id: 'notebook_with_decorative_cover',
    shortcode: 'notebook_with_decorative_cover',
  },
  {
    id: 'closed_book',
    shortcode: 'closed_book',
  },
  {
    id: 'book',
    shortcode: 'book',
  },
  {
    id: 'book',
    shortcode: 'open_book',
  },
  {
    id: 'green_book',
    shortcode: 'green_book',
  },
  {
    id: 'blue_book',
    shortcode: 'blue_book',
  },
  {
    id: 'orange_book',
    shortcode: 'orange_book',
  },
  {
    id: 'books',
    shortcode: 'books',
  },
  {
    id: 'notebook',
    shortcode: 'notebook',
  },
  {
    id: 'ledger',
    shortcode: 'ledger',
  },
  {
    id: 'page_with_curl',
    shortcode: 'page_with_curl',
  },
  {
    id: 'scroll',
    shortcode: 'scroll',
  },
  {
    id: 'page_facing_up',
    shortcode: 'page_facing_up',
  },
  {
    id: 'newspaper',
    shortcode: 'newspaper',
  },
  {
    id: 'rolled_up_newspaper',
    shortcode: 'rolled_up_newspaper',
  },
  {
    id: 'bookmark_tabs',
    shortcode: 'bookmark_tabs',
  },
  {
    id: 'bookmark',
    shortcode: 'bookmark',
  },
  {
    id: 'label',
    shortcode: 'label',
  },
  {
    id: 'moneybag',
    shortcode: 'moneybag',
  },
  {
    id: 'yen',
    shortcode: 'yen',
  },
  {
    id: 'dollar',
    shortcode: 'dollar',
  },
  {
    id: 'euro',
    shortcode: 'euro',
  },
  {
    id: 'pound',
    shortcode: 'pound',
  },
  {
    id: 'money_with_wings',
    shortcode: 'money_with_wings',
  },
  {
    id: 'credit_card',
    shortcode: 'credit_card',
  },
  {
    id: 'chart',
    shortcode: 'chart',
  },
  {
    id: 'currency_exchange',
    shortcode: 'currency_exchange',
  },
  {
    id: 'heavy_dollar_sign',
    shortcode: 'heavy_dollar_sign',
  },
  {
    id: 'email',
    shortcode: 'email',
  },
  {
    id: 'email',
    shortcode: 'envelope',
  },
  {
    id: 'e-mail',
    shortcode: 'e-mail',
  },
  {
    id: 'incoming_envelope',
    shortcode: 'incoming_envelope',
  },
  {
    id: 'envelope_with_arrow',
    shortcode: 'envelope_with_arrow',
  },
  {
    id: 'outbox_tray',
    shortcode: 'outbox_tray',
  },
  {
    id: 'inbox_tray',
    shortcode: 'inbox_tray',
  },
  {
    id: 'package',
    shortcode: 'package',
  },
  {
    id: 'mailbox',
    shortcode: 'mailbox',
  },
  {
    id: 'mailbox_closed',
    shortcode: 'mailbox_closed',
  },
  {
    id: 'mailbox_with_mail',
    shortcode: 'mailbox_with_mail',
  },
  {
    id: 'mailbox_with_no_mail',
    shortcode: 'mailbox_with_no_mail',
  },
  {
    id: 'postbox',
    shortcode: 'postbox',
  },
  {
    id: 'ballot_box_with_ballot',
    shortcode: 'ballot_box_with_ballot',
  },
  {
    id: 'pencil2',
    shortcode: 'pencil2',
  },
  {
    id: 'black_nib',
    shortcode: 'black_nib',
  },
  {
    id: 'lower_left_fountain_pen',
    shortcode: 'lower_left_fountain_pen',
  },
  {
    id: 'lower_left_ballpoint_pen',
    shortcode: 'lower_left_ballpoint_pen',
  },
  {
    id: 'lower_left_paintbrush',
    shortcode: 'lower_left_paintbrush',
  },
  {
    id: 'lower_left_crayon',
    shortcode: 'lower_left_crayon',
  },
  {
    id: 'memo',
    shortcode: 'memo',
  },
  {
    id: 'memo',
    shortcode: 'pencil',
  },
  {
    id: 'briefcase',
    shortcode: 'briefcase',
  },
  {
    id: 'file_folder',
    shortcode: 'file_folder',
  },
  {
    id: 'open_file_folder',
    shortcode: 'open_file_folder',
  },
  {
    id: 'card_index_dividers',
    shortcode: 'card_index_dividers',
  },
  {
    id: 'date',
    shortcode: 'date',
  },
  {
    id: 'calendar',
    shortcode: 'calendar',
  },
  {
    id: 'spiral_note_pad',
    shortcode: 'spiral_note_pad',
  },
  {
    id: 'spiral_calendar_pad',
    shortcode: 'spiral_calendar_pad',
  },
  {
    id: 'card_index',
    shortcode: 'card_index',
  },
  {
    id: 'chart_with_upwards_trend',
    shortcode: 'chart_with_upwards_trend',
  },
  {
    id: 'chart_with_downwards_trend',
    shortcode: 'chart_with_downwards_trend',
  },
  {
    id: 'bar_chart',
    shortcode: 'bar_chart',
  },
  {
    id: 'clipboard',
    shortcode: 'clipboard',
  },
  {
    id: 'pushpin',
    shortcode: 'pushpin',
  },
  {
    id: 'round_pushpin',
    shortcode: 'round_pushpin',
  },
  {
    id: 'paperclip',
    shortcode: 'paperclip',
  },
  {
    id: 'linked_paperclips',
    shortcode: 'linked_paperclips',
  },
  {
    id: 'straight_ruler',
    shortcode: 'straight_ruler',
  },
  {
    id: 'triangular_ruler',
    shortcode: 'triangular_ruler',
  },
  {
    id: 'scissors',
    shortcode: 'scissors',
  },
  {
    id: 'card_file_box',
    shortcode: 'card_file_box',
  },
  {
    id: 'file_cabinet',
    shortcode: 'file_cabinet',
  },
  {
    id: 'wastebasket',
    shortcode: 'wastebasket',
  },
  {
    id: 'lock',
    shortcode: 'lock',
  },
  {
    id: 'unlock',
    shortcode: 'unlock',
  },
  {
    id: 'lock_with_ink_pen',
    shortcode: 'lock_with_ink_pen',
  },
  {
    id: 'closed_lock_with_key',
    shortcode: 'closed_lock_with_key',
  },
  {
    id: 'key',
    shortcode: 'key',
  },
  {
    id: 'old_key',
    shortcode: 'old_key',
  },
  {
    id: 'hammer',
    shortcode: 'hammer',
  },
  {
    id: 'pick',
    shortcode: 'pick',
  },
  {
    id: 'hammer_and_pick',
    shortcode: 'hammer_and_pick',
  },
  {
    id: 'hammer_and_wrench',
    shortcode: 'hammer_and_wrench',
  },
  {
    id: 'dagger_knife',
    shortcode: 'dagger_knife',
  },
  {
    id: 'crossed_swords',
    shortcode: 'crossed_swords',
  },
  {
    id: 'gun',
    shortcode: 'gun',
  },
  {
    id: 'bow_and_arrow',
    shortcode: 'bow_and_arrow',
  },
  {
    id: 'shield',
    shortcode: 'shield',
  },
  {
    id: 'wrench',
    shortcode: 'wrench',
  },
  {
    id: 'nut_and_bolt',
    shortcode: 'nut_and_bolt',
  },
  {
    id: 'gear',
    shortcode: 'gear',
  },
  {
    id: 'compression',
    shortcode: 'compression',
  },
  {
    id: 'alembic',
    shortcode: 'alembic',
  },
  {
    id: 'scales',
    shortcode: 'scales',
  },
  {
    id: 'link',
    shortcode: 'link',
  },
  {
    id: 'chains',
    shortcode: 'chains',
  },
  {
    id: 'syringe',
    shortcode: 'syringe',
  },
  {
    id: 'pill',
    shortcode: 'pill',
  },
  {
    id: 'smoking',
    shortcode: 'smoking',
  },
  {
    id: 'coffin',
    shortcode: 'coffin',
  },
  {
    id: 'funeral_urn',
    shortcode: 'funeral_urn',
  },
  {
    id: 'moyai',
    shortcode: 'moyai',
  },
  {
    id: 'oil_drum',
    shortcode: 'oil_drum',
  },
  {
    id: 'crystal_ball',
    shortcode: 'crystal_ball',
  },
  {
    id: 'shopping_trolley',
    shortcode: 'shopping_trolley',
  },
  {
    id: 'atm',
    shortcode: 'atm',
  },
  {
    id: 'put_litter_in_its_place',
    shortcode: 'put_litter_in_its_place',
  },
  {
    id: 'potable_water',
    shortcode: 'potable_water',
  },
  {
    id: 'wheelchair',
    shortcode: 'wheelchair',
  },
  {
    id: 'mens',
    shortcode: 'mens',
  },
  {
    id: 'womens',
    shortcode: 'womens',
  },
  {
    id: 'restroom',
    shortcode: 'restroom',
  },
  {
    id: 'baby_symbol',
    shortcode: 'baby_symbol',
  },
  {
    id: 'wc',
    shortcode: 'wc',
  },
  {
    id: 'passport_control',
    shortcode: 'passport_control',
  },
  {
    id: 'customs',
    shortcode: 'customs',
  },
  {
    id: 'baggage_claim',
    shortcode: 'baggage_claim',
  },
  {
    id: 'left_luggage',
    shortcode: 'left_luggage',
  },
  {
    id: 'warning',
    shortcode: 'warning',
  },
  {
    id: 'children_crossing',
    shortcode: 'children_crossing',
  },
  {
    id: 'no_entry',
    shortcode: 'no_entry',
  },
  {
    id: 'no_entry_sign',
    shortcode: 'no_entry_sign',
  },
  {
    id: 'no_bicycles',
    shortcode: 'no_bicycles',
  },
  {
    id: 'no_smoking',
    shortcode: 'no_smoking',
  },
  {
    id: 'do_not_litter',
    shortcode: 'do_not_litter',
  },
  {
    id: 'non-potable_water',
    shortcode: 'non-potable_water',
  },
  {
    id: 'no_pedestrians',
    shortcode: 'no_pedestrians',
  },
  {
    id: 'no_mobile_phones',
    shortcode: 'no_mobile_phones',
  },
  {
    id: 'underage',
    shortcode: 'underage',
  },
  {
    id: 'radioactive_sign',
    shortcode: 'radioactive_sign',
  },
  {
    id: 'biohazard_sign',
    shortcode: 'biohazard_sign',
  },
  {
    id: 'arrow_up',
    shortcode: 'arrow_up',
  },
  {
    id: 'arrow_upper_right',
    shortcode: 'arrow_upper_right',
  },
  {
    id: 'arrow_right',
    shortcode: 'arrow_right',
  },
  {
    id: 'arrow_lower_right',
    shortcode: 'arrow_lower_right',
  },
  {
    id: 'arrow_down',
    shortcode: 'arrow_down',
  },
  {
    id: 'arrow_lower_left',
    shortcode: 'arrow_lower_left',
  },
  {
    id: 'arrow_left',
    shortcode: 'arrow_left',
  },
  {
    id: 'arrow_upper_left',
    shortcode: 'arrow_upper_left',
  },
  {
    id: 'arrow_up_down',
    shortcode: 'arrow_up_down',
  },
  {
    id: 'left_right_arrow',
    shortcode: 'left_right_arrow',
  },
  {
    id: 'leftwards_arrow_with_hook',
    shortcode: 'leftwards_arrow_with_hook',
  },
  {
    id: 'arrow_right_hook',
    shortcode: 'arrow_right_hook',
  },
  {
    id: 'arrow_heading_up',
    shortcode: 'arrow_heading_up',
  },
  {
    id: 'arrow_heading_down',
    shortcode: 'arrow_heading_down',
  },
  {
    id: 'arrows_clockwise',
    shortcode: 'arrows_clockwise',
  },
  {
    id: 'arrows_counterclockwise',
    shortcode: 'arrows_counterclockwise',
  },
  {
    id: 'back',
    shortcode: 'back',
  },
  {
    id: 'end',
    shortcode: 'end',
  },
  {
    id: 'on',
    shortcode: 'on',
  },
  {
    id: 'soon',
    shortcode: 'soon',
  },
  {
    id: 'top',
    shortcode: 'top',
  },
  {
    id: 'place_of_worship',
    shortcode: 'place_of_worship',
  },
  {
    id: 'atom_symbol',
    shortcode: 'atom_symbol',
  },
  {
    id: 'om_symbol',
    shortcode: 'om_symbol',
  },
  {
    id: 'star_of_david',
    shortcode: 'star_of_david',
  },
  {
    id: 'wheel_of_dharma',
    shortcode: 'wheel_of_dharma',
  },
  {
    id: 'yin_yang',
    shortcode: 'yin_yang',
  },
  {
    id: 'latin_cross',
    shortcode: 'latin_cross',
  },
  {
    id: 'orthodox_cross',
    shortcode: 'orthodox_cross',
  },
  {
    id: 'star_and_crescent',
    shortcode: 'star_and_crescent',
  },
  {
    id: 'peace_symbol',
    shortcode: 'peace_symbol',
  },
  {
    id: 'menorah_with_nine_branches',
    shortcode: 'menorah_with_nine_branches',
  },
  {
    id: 'six_pointed_star',
    shortcode: 'six_pointed_star',
  },
  {
    id: 'aries',
    shortcode: 'aries',
  },
  {
    id: 'taurus',
    shortcode: 'taurus',
  },
  {
    id: 'gemini',
    shortcode: 'gemini',
  },
  {
    id: 'cancer',
    shortcode: 'cancer',
  },
  {
    id: 'leo',
    shortcode: 'leo',
  },
  {
    id: 'virgo',
    shortcode: 'virgo',
  },
  {
    id: 'libra',
    shortcode: 'libra',
  },
  {
    id: 'scorpius',
    shortcode: 'scorpius',
  },
  {
    id: 'sagittarius',
    shortcode: 'sagittarius',
  },
  {
    id: 'capricorn',
    shortcode: 'capricorn',
  },
  {
    id: 'aquarius',
    shortcode: 'aquarius',
  },
  {
    id: 'pisces',
    shortcode: 'pisces',
  },
  {
    id: 'ophiuchus',
    shortcode: 'ophiuchus',
  },
  {
    id: 'twisted_rightwards_arrows',
    shortcode: 'twisted_rightwards_arrows',
  },
  {
    id: 'repeat',
    shortcode: 'repeat',
  },
  {
    id: 'repeat_one',
    shortcode: 'repeat_one',
  },
  {
    id: 'arrow_forward',
    shortcode: 'arrow_forward',
  },
  {
    id: 'fast_forward',
    shortcode: 'fast_forward',
  },
  {
    id: 'black_right_pointing_double_triangle_with_vertical_bar',
    shortcode: 'black_right_pointing_double_triangle_with_vertical_bar',
  },
  {
    id: 'black_right_pointing_triangle_with_double_vertical_bar',
    shortcode: 'black_right_pointing_triangle_with_double_vertical_bar',
  },
  {
    id: 'arrow_backward',
    shortcode: 'arrow_backward',
  },
  {
    id: 'rewind',
    shortcode: 'rewind',
  },
  {
    id: 'black_left_pointing_double_triangle_with_vertical_bar',
    shortcode: 'black_left_pointing_double_triangle_with_vertical_bar',
  },
  {
    id: 'arrow_up_small',
    shortcode: 'arrow_up_small',
  },
  {
    id: 'arrow_double_up',
    shortcode: 'arrow_double_up',
  },
  {
    id: 'arrow_down_small',
    shortcode: 'arrow_down_small',
  },
  {
    id: 'arrow_double_down',
    shortcode: 'arrow_double_down',
  },
  {
    id: 'double_vertical_bar',
    shortcode: 'double_vertical_bar',
  },
  {
    id: 'black_square_for_stop',
    shortcode: 'black_square_for_stop',
  },
  {
    id: 'black_circle_for_record',
    shortcode: 'black_circle_for_record',
  },
  {
    id: 'eject',
    shortcode: 'eject',
  },
  {
    id: 'cinema',
    shortcode: 'cinema',
  },
  {
    id: 'low_brightness',
    shortcode: 'low_brightness',
  },
  {
    id: 'high_brightness',
    shortcode: 'high_brightness',
  },
  {
    id: 'signal_strength',
    shortcode: 'signal_strength',
  },
  {
    id: 'vibration_mode',
    shortcode: 'vibration_mode',
  },
  {
    id: 'mobile_phone_off',
    shortcode: 'mobile_phone_off',
  },
  {
    id: 'recycle',
    shortcode: 'recycle',
  },
  {
    id: 'fleur_de_lis',
    shortcode: 'fleur_de_lis',
  },
  {
    id: 'trident',
    shortcode: 'trident',
  },
  {
    id: 'name_badge',
    shortcode: 'name_badge',
  },
  {
    id: 'beginner',
    shortcode: 'beginner',
  },
  {
    id: 'o',
    shortcode: 'o',
  },
  {
    id: 'white_check_mark',
    shortcode: 'white_check_mark',
  },
  {
    id: 'ballot_box_with_check',
    shortcode: 'ballot_box_with_check',
  },
  {
    id: 'heavy_check_mark',
    shortcode: 'heavy_check_mark',
  },
  {
    id: 'heavy_multiplication_x',
    shortcode: 'heavy_multiplication_x',
  },
  {
    id: 'x',
    shortcode: 'x',
  },
  {
    id: 'negative_squared_cross_mark',
    shortcode: 'negative_squared_cross_mark',
  },
  {
    id: 'heavy_plus_sign',
    shortcode: 'heavy_plus_sign',
  },
  {
    id: 'heavy_minus_sign',
    shortcode: 'heavy_minus_sign',
  },
  {
    id: 'heavy_division_sign',
    shortcode: 'heavy_division_sign',
  },
  {
    id: 'curly_loop',
    shortcode: 'curly_loop',
  },
  {
    id: 'loop',
    shortcode: 'loop',
  },
  {
    id: 'part_alternation_mark',
    shortcode: 'part_alternation_mark',
  },
  {
    id: 'eight_spoked_asterisk',
    shortcode: 'eight_spoked_asterisk',
  },
  {
    id: 'eight_pointed_black_star',
    shortcode: 'eight_pointed_black_star',
  },
  {
    id: 'sparkle',
    shortcode: 'sparkle',
  },
  {
    id: 'bangbang',
    shortcode: 'bangbang',
  },
  {
    id: 'interrobang',
    shortcode: 'interrobang',
  },
  {
    id: 'question',
    shortcode: 'question',
  },
  {
    id: 'grey_question',
    shortcode: 'grey_question',
  },
  {
    id: 'grey_exclamation',
    shortcode: 'grey_exclamation',
  },
  {
    id: 'exclamation',
    shortcode: 'exclamation',
  },
  {
    id: 'exclamation',
    shortcode: 'heavy_exclamation_mark',
  },
  {
    id: 'wavy_dash',
    shortcode: 'wavy_dash',
  },
  {
    id: 'copyright',
    shortcode: 'copyright',
  },
  {
    id: 'registered',
    shortcode: 'registered',
  },
  {
    id: 'tm',
    shortcode: 'tm',
  },
  {
    id: 'hash',
    shortcode: 'hash',
  },
  {
    id: 'keycap_star',
    shortcode: 'keycap_star',
  },
  {
    id: 'zero',
    shortcode: 'zero',
  },
  {
    id: 'one',
    shortcode: 'one',
  },
  {
    id: 'two',
    shortcode: 'two',
  },
  {
    id: 'three',
    shortcode: 'three',
  },
  {
    id: 'four',
    shortcode: 'four',
  },
  {
    id: 'five',
    shortcode: 'five',
  },
  {
    id: 'six',
    shortcode: 'six',
  },
  {
    id: 'seven',
    shortcode: 'seven',
  },
  {
    id: 'eight',
    shortcode: 'eight',
  },
  {
    id: 'nine',
    shortcode: 'nine',
  },
  {
    id: 'keycap_ten',
    shortcode: 'keycap_ten',
  },
  {
    id: '100',
    shortcode: '100',
  },
  {
    id: 'capital_abcd',
    shortcode: 'capital_abcd',
  },
  {
    id: 'abcd',
    shortcode: 'abcd',
  },
  {
    id: '1234',
    shortcode: '1234',
  },
  {
    id: 'symbols',
    shortcode: 'symbols',
  },
  {
    id: 'abc',
    shortcode: 'abc',
  },
  {
    id: 'a',
    shortcode: 'a',
  },
  {
    id: 'ab',
    shortcode: 'ab',
  },
  {
    id: 'b',
    shortcode: 'b',
  },
  {
    id: 'cl',
    shortcode: 'cl',
  },
  {
    id: 'cool',
    shortcode: 'cool',
  },
  {
    id: 'free',
    shortcode: 'free',
  },
  {
    id: 'information_source',
    shortcode: 'information_source',
  },
  {
    id: 'id',
    shortcode: 'id',
  },
  {
    id: 'm',
    shortcode: 'm',
  },
  {
    id: 'new',
    shortcode: 'new',
  },
  {
    id: 'ng',
    shortcode: 'ng',
  },
  {
    id: 'o2',
    shortcode: 'o2',
  },
  {
    id: 'ok',
    shortcode: 'ok',
  },
  {
    id: 'parking',
    shortcode: 'parking',
  },
  {
    id: 'sos',
    shortcode: 'sos',
  },
  {
    id: 'up',
    shortcode: 'up',
  },
  {
    id: 'vs',
    shortcode: 'vs',
  },
  {
    id: 'koko',
    shortcode: 'koko',
  },
  {
    id: 'sa',
    shortcode: 'sa',
  },
  {
    id: 'u6708',
    shortcode: 'u6708',
  },
  {
    id: 'u6709',
    shortcode: 'u6709',
  },
  {
    id: 'u6307',
    shortcode: 'u6307',
  },
  {
    id: 'ideograph_advantage',
    shortcode: 'ideograph_advantage',
  },
  {
    id: 'u5272',
    shortcode: 'u5272',
  },
  {
    id: 'u7121',
    shortcode: 'u7121',
  },
  {
    id: 'u7981',
    shortcode: 'u7981',
  },
  {
    id: 'accept',
    shortcode: 'accept',
  },
  {
    id: 'u7533',
    shortcode: 'u7533',
  },
  {
    id: 'u5408',
    shortcode: 'u5408',
  },
  {
    id: 'u7a7a',
    shortcode: 'u7a7a',
  },
  {
    id: 'congratulations',
    shortcode: 'congratulations',
  },
  {
    id: 'secret',
    shortcode: 'secret',
  },
  {
    id: 'u55b6',
    shortcode: 'u55b6',
  },
  {
    id: 'u6e80',
    shortcode: 'u6e80',
  },
  {
    id: 'black_small_square',
    shortcode: 'black_small_square',
  },
  {
    id: 'white_small_square',
    shortcode: 'white_small_square',
  },
  {
    id: 'white_medium_square',
    shortcode: 'white_medium_square',
  },
  {
    id: 'black_medium_square',
    shortcode: 'black_medium_square',
  },
  {
    id: 'white_medium_small_square',
    shortcode: 'white_medium_small_square',
  },
  {
    id: 'black_medium_small_square',
    shortcode: 'black_medium_small_square',
  },
  {
    id: 'black_large_square',
    shortcode: 'black_large_square',
  },
  {
    id: 'white_large_square',
    shortcode: 'white_large_square',
  },
  {
    id: 'large_orange_diamond',
    shortcode: 'large_orange_diamond',
  },
  {
    id: 'large_blue_diamond',
    shortcode: 'large_blue_diamond',
  },
  {
    id: 'small_orange_diamond',
    shortcode: 'small_orange_diamond',
  },
  {
    id: 'small_blue_diamond',
    shortcode: 'small_blue_diamond',
  },
  {
    id: 'small_red_triangle',
    shortcode: 'small_red_triangle',
  },
  {
    id: 'small_red_triangle_down',
    shortcode: 'small_red_triangle_down',
  },
  {
    id: 'diamond_shape_with_a_dot_inside',
    shortcode: 'diamond_shape_with_a_dot_inside',
  },
  {
    id: 'radio_button',
    shortcode: 'radio_button',
  },
  {
    id: 'black_square_button',
    shortcode: 'black_square_button',
  },
  {
    id: 'white_square_button',
    shortcode: 'white_square_button',
  },
  {
    id: 'white_circle',
    shortcode: 'white_circle',
  },
  {
    id: 'black_circle',
    shortcode: 'black_circle',
  },
  {
    id: 'red_circle',
    shortcode: 'red_circle',
  },
  {
    id: 'large_blue_circle',
    shortcode: 'large_blue_circle',
  },
  {
    id: 'checkered_flag',
    shortcode: 'checkered_flag',
  },
  {
    id: 'cn',
    shortcode: 'cn',
  },
  {
    id: 'cn',
    shortcode: 'flag-cn',
  },
  {
    id: 'crossed_flags',
    shortcode: 'crossed_flags',
  },
  {
    id: 'de',
    shortcode: 'de',
  },
  {
    id: 'de',
    shortcode: 'flag-de',
  },
  {
    id: 'es',
    shortcode: 'es',
  },
  {
    id: 'es',
    shortcode: 'flag-es',
  },
  {
    id: 'flag-ac',
    shortcode: 'flag-ac',
  },
  {
    id: 'flag-ad',
    shortcode: 'flag-ad',
  },
  {
    id: 'flag-ae',
    shortcode: 'flag-ae',
  },
  {
    id: 'flag-af',
    shortcode: 'flag-af',
  },
  {
    id: 'flag-ag',
    shortcode: 'flag-ag',
  },
  {
    id: 'flag-ai',
    shortcode: 'flag-ai',
  },
  {
    id: 'flag-al',
    shortcode: 'flag-al',
  },
  {
    id: 'flag-am',
    shortcode: 'flag-am',
  },
  {
    id: 'flag-ao',
    shortcode: 'flag-ao',
  },
  {
    id: 'flag-aq',
    shortcode: 'flag-aq',
  },
  {
    id: 'flag-ar',
    shortcode: 'flag-ar',
  },
  {
    id: 'flag-as',
    shortcode: 'flag-as',
  },
  {
    id: 'flag-at',
    shortcode: 'flag-at',
  },
  {
    id: 'flag-au',
    shortcode: 'flag-au',
  },
  {
    id: 'flag-aw',
    shortcode: 'flag-aw',
  },
  {
    id: 'flag-ax',
    shortcode: 'flag-ax',
  },
  {
    id: 'flag-az',
    shortcode: 'flag-az',
  },
  {
    id: 'flag-ba',
    shortcode: 'flag-ba',
  },
  {
    id: 'flag-bb',
    shortcode: 'flag-bb',
  },
  {
    id: 'flag-bd',
    shortcode: 'flag-bd',
  },
  {
    id: 'flag-be',
    shortcode: 'flag-be',
  },
  {
    id: 'flag-bf',
    shortcode: 'flag-bf',
  },
  {
    id: 'flag-bg',
    shortcode: 'flag-bg',
  },
  {
    id: 'flag-bh',
    shortcode: 'flag-bh',
  },
  {
    id: 'flag-bi',
    shortcode: 'flag-bi',
  },
  {
    id: 'flag-bj',
    shortcode: 'flag-bj',
  },
  {
    id: 'flag-bl',
    shortcode: 'flag-bl',
  },
  {
    id: 'flag-bm',
    shortcode: 'flag-bm',
  },
  {
    id: 'flag-bn',
    shortcode: 'flag-bn',
  },
  {
    id: 'flag-bo',
    shortcode: 'flag-bo',
  },
  {
    id: 'flag-bq',
    shortcode: 'flag-bq',
  },
  {
    id: 'flag-br',
    shortcode: 'flag-br',
  },
  {
    id: 'flag-bs',
    shortcode: 'flag-bs',
  },
  {
    id: 'flag-bt',
    shortcode: 'flag-bt',
  },
  {
    id: 'flag-bv',
    shortcode: 'flag-bv',
  },
  {
    id: 'flag-bw',
    shortcode: 'flag-bw',
  },
  {
    id: 'flag-by',
    shortcode: 'flag-by',
  },
  {
    id: 'flag-bz',
    shortcode: 'flag-bz',
  },
  {
    id: 'flag-ca',
    shortcode: 'flag-ca',
  },
  {
    id: 'flag-cc',
    shortcode: 'flag-cc',
  },
  {
    id: 'flag-cd',
    shortcode: 'flag-cd',
  },
  {
    id: 'flag-cf',
    shortcode: 'flag-cf',
  },
  {
    id: 'flag-cg',
    shortcode: 'flag-cg',
  },
  {
    id: 'flag-ch',
    shortcode: 'flag-ch',
  },
  {
    id: 'flag-ci',
    shortcode: 'flag-ci',
  },
  {
    id: 'flag-ck',
    shortcode: 'flag-ck',
  },
  {
    id: 'flag-cl',
    shortcode: 'flag-cl',
  },
  {
    id: 'flag-cm',
    shortcode: 'flag-cm',
  },
  {
    id: 'flag-co',
    shortcode: 'flag-co',
  },
  {
    id: 'flag-cp',
    shortcode: 'flag-cp',
  },
  {
    id: 'flag-cr',
    shortcode: 'flag-cr',
  },
  {
    id: 'flag-cu',
    shortcode: 'flag-cu',
  },
  {
    id: 'flag-cv',
    shortcode: 'flag-cv',
  },
  {
    id: 'flag-cw',
    shortcode: 'flag-cw',
  },
  {
    id: 'flag-cx',
    shortcode: 'flag-cx',
  },
  {
    id: 'flag-cy',
    shortcode: 'flag-cy',
  },
  {
    id: 'flag-cz',
    shortcode: 'flag-cz',
  },
  {
    id: 'flag-dg',
    shortcode: 'flag-dg',
  },
  {
    id: 'flag-dj',
    shortcode: 'flag-dj',
  },
  {
    id: 'flag-dk',
    shortcode: 'flag-dk',
  },
  {
    id: 'flag-dm',
    shortcode: 'flag-dm',
  },
  {
    id: 'flag-do',
    shortcode: 'flag-do',
  },
  {
    id: 'flag-dz',
    shortcode: 'flag-dz',
  },
  {
    id: 'flag-ea',
    shortcode: 'flag-ea',
  },
  {
    id: 'flag-ec',
    shortcode: 'flag-ec',
  },
  {
    id: 'flag-ee',
    shortcode: 'flag-ee',
  },
  {
    id: 'flag-eg',
    shortcode: 'flag-eg',
  },
  {
    id: 'flag-eh',
    shortcode: 'flag-eh',
  },
  {
    id: 'flag-england',
    shortcode: 'flag-england',
  },
  {
    id: 'flag-er',
    shortcode: 'flag-er',
  },
  {
    id: 'flag-et',
    shortcode: 'flag-et',
  },
  {
    id: 'flag-eu',
    shortcode: 'flag-eu',
  },
  {
    id: 'flag-fi',
    shortcode: 'flag-fi',
  },
  {
    id: 'flag-fj',
    shortcode: 'flag-fj',
  },
  {
    id: 'flag-fk',
    shortcode: 'flag-fk',
  },
  {
    id: 'flag-fm',
    shortcode: 'flag-fm',
  },
  {
    id: 'flag-fo',
    shortcode: 'flag-fo',
  },
  {
    id: 'flag-ga',
    shortcode: 'flag-ga',
  },
  {
    id: 'flag-gd',
    shortcode: 'flag-gd',
  },
  {
    id: 'flag-ge',
    shortcode: 'flag-ge',
  },
  {
    id: 'flag-gf',
    shortcode: 'flag-gf',
  },
  {
    id: 'flag-gg',
    shortcode: 'flag-gg',
  },
  {
    id: 'flag-gh',
    shortcode: 'flag-gh',
  },
  {
    id: 'flag-gi',
    shortcode: 'flag-gi',
  },
  {
    id: 'flag-gl',
    shortcode: 'flag-gl',
  },
  {
    id: 'flag-gm',
    shortcode: 'flag-gm',
  },
  {
    id: 'flag-gn',
    shortcode: 'flag-gn',
  },
  {
    id: 'flag-gp',
    shortcode: 'flag-gp',
  },
  {
    id: 'flag-gq',
    shortcode: 'flag-gq',
  },
  {
    id: 'flag-gr',
    shortcode: 'flag-gr',
  },
  {
    id: 'flag-gs',
    shortcode: 'flag-gs',
  },
  {
    id: 'flag-gt',
    shortcode: 'flag-gt',
  },
  {
    id: 'flag-gu',
    shortcode: 'flag-gu',
  },
  {
    id: 'flag-gw',
    shortcode: 'flag-gw',
  },
  {
    id: 'flag-gy',
    shortcode: 'flag-gy',
  },
  {
    id: 'flag-hk',
    shortcode: 'flag-hk',
  },
  {
    id: 'flag-hm',
    shortcode: 'flag-hm',
  },
  {
    id: 'flag-hn',
    shortcode: 'flag-hn',
  },
  {
    id: 'flag-hr',
    shortcode: 'flag-hr',
  },
  {
    id: 'flag-ht',
    shortcode: 'flag-ht',
  },
  {
    id: 'flag-hu',
    shortcode: 'flag-hu',
  },
  {
    id: 'flag-ic',
    shortcode: 'flag-ic',
  },
  {
    id: 'flag-id',
    shortcode: 'flag-id',
  },
  {
    id: 'flag-ie',
    shortcode: 'flag-ie',
  },
  {
    id: 'flag-il',
    shortcode: 'flag-il',
  },
  {
    id: 'flag-im',
    shortcode: 'flag-im',
  },
  {
    id: 'flag-in',
    shortcode: 'flag-in',
  },
  {
    id: 'flag-io',
    shortcode: 'flag-io',
  },
  {
    id: 'flag-iq',
    shortcode: 'flag-iq',
  },
  {
    id: 'flag-ir',
    shortcode: 'flag-ir',
  },
  {
    id: 'flag-is',
    shortcode: 'flag-is',
  },
  {
    id: 'flag-je',
    shortcode: 'flag-je',
  },
  {
    id: 'flag-jm',
    shortcode: 'flag-jm',
  },
  {
    id: 'flag-jo',
    shortcode: 'flag-jo',
  },
  {
    id: 'flag-ke',
    shortcode: 'flag-ke',
  },
  {
    id: 'flag-kg',
    shortcode: 'flag-kg',
  },
  {
    id: 'flag-kh',
    shortcode: 'flag-kh',
  },
  {
    id: 'flag-ki',
    shortcode: 'flag-ki',
  },
  {
    id: 'flag-km',
    shortcode: 'flag-km',
  },
  {
    id: 'flag-kn',
    shortcode: 'flag-kn',
  },
  {
    id: 'flag-kp',
    shortcode: 'flag-kp',
  },
  {
    id: 'flag-kw',
    shortcode: 'flag-kw',
  },
  {
    id: 'flag-ky',
    shortcode: 'flag-ky',
  },
  {
    id: 'flag-kz',
    shortcode: 'flag-kz',
  },
  {
    id: 'flag-la',
    shortcode: 'flag-la',
  },
  {
    id: 'flag-lb',
    shortcode: 'flag-lb',
  },
  {
    id: 'flag-lc',
    shortcode: 'flag-lc',
  },
  {
    id: 'flag-li',
    shortcode: 'flag-li',
  },
  {
    id: 'flag-lk',
    shortcode: 'flag-lk',
  },
  {
    id: 'flag-lr',
    shortcode: 'flag-lr',
  },
  {
    id: 'flag-ls',
    shortcode: 'flag-ls',
  },
  {
    id: 'flag-lt',
    shortcode: 'flag-lt',
  },
  {
    id: 'flag-lu',
    shortcode: 'flag-lu',
  },
  {
    id: 'flag-lv',
    shortcode: 'flag-lv',
  },
  {
    id: 'flag-ly',
    shortcode: 'flag-ly',
  },
  {
    id: 'flag-ma',
    shortcode: 'flag-ma',
  },
  {
    id: 'flag-mc',
    shortcode: 'flag-mc',
  },
  {
    id: 'flag-md',
    shortcode: 'flag-md',
  },
  {
    id: 'flag-me',
    shortcode: 'flag-me',
  },
  {
    id: 'flag-mf',
    shortcode: 'flag-mf',
  },
  {
    id: 'flag-mg',
    shortcode: 'flag-mg',
  },
  {
    id: 'flag-mh',
    shortcode: 'flag-mh',
  },
  {
    id: 'flag-mk',
    shortcode: 'flag-mk',
  },
  {
    id: 'flag-ml',
    shortcode: 'flag-ml',
  },
  {
    id: 'flag-mm',
    shortcode: 'flag-mm',
  },
  {
    id: 'flag-mn',
    shortcode: 'flag-mn',
  },
  {
    id: 'flag-mo',
    shortcode: 'flag-mo',
  },
  {
    id: 'flag-mp',
    shortcode: 'flag-mp',
  },
  {
    id: 'flag-mq',
    shortcode: 'flag-mq',
  },
  {
    id: 'flag-mr',
    shortcode: 'flag-mr',
  },
  {
    id: 'flag-ms',
    shortcode: 'flag-ms',
  },
  {
    id: 'flag-mt',
    shortcode: 'flag-mt',
  },
  {
    id: 'flag-mu',
    shortcode: 'flag-mu',
  },
  {
    id: 'flag-mv',
    shortcode: 'flag-mv',
  },
  {
    id: 'flag-mw',
    shortcode: 'flag-mw',
  },
  {
    id: 'flag-mx',
    shortcode: 'flag-mx',
  },
  {
    id: 'flag-my',
    shortcode: 'flag-my',
  },
  {
    id: 'flag-mz',
    shortcode: 'flag-mz',
  },
  {
    id: 'flag-na',
    shortcode: 'flag-na',
  },
  {
    id: 'flag-nc',
    shortcode: 'flag-nc',
  },
  {
    id: 'flag-ne',
    shortcode: 'flag-ne',
  },
  {
    id: 'flag-nf',
    shortcode: 'flag-nf',
  },
  {
    id: 'flag-ng',
    shortcode: 'flag-ng',
  },
  {
    id: 'flag-ni',
    shortcode: 'flag-ni',
  },
  {
    id: 'flag-nl',
    shortcode: 'flag-nl',
  },
  {
    id: 'flag-no',
    shortcode: 'flag-no',
  },
  {
    id: 'flag-np',
    shortcode: 'flag-np',
  },
  {
    id: 'flag-nr',
    shortcode: 'flag-nr',
  },
  {
    id: 'flag-nu',
    shortcode: 'flag-nu',
  },
  {
    id: 'flag-nz',
    shortcode: 'flag-nz',
  },
  {
    id: 'flag-om',
    shortcode: 'flag-om',
  },
  {
    id: 'flag-pa',
    shortcode: 'flag-pa',
  },
  {
    id: 'flag-pe',
    shortcode: 'flag-pe',
  },
  {
    id: 'flag-pf',
    shortcode: 'flag-pf',
  },
  {
    id: 'flag-pg',
    shortcode: 'flag-pg',
  },
  {
    id: 'flag-ph',
    shortcode: 'flag-ph',
  },
  {
    id: 'flag-pk',
    shortcode: 'flag-pk',
  },
  {
    id: 'flag-pl',
    shortcode: 'flag-pl',
  },
  {
    id: 'flag-pm',
    shortcode: 'flag-pm',
  },
  {
    id: 'flag-pn',
    shortcode: 'flag-pn',
  },
  {
    id: 'flag-pr',
    shortcode: 'flag-pr',
  },
  {
    id: 'flag-ps',
    shortcode: 'flag-ps',
  },
  {
    id: 'flag-pt',
    shortcode: 'flag-pt',
  },
  {
    id: 'flag-pw',
    shortcode: 'flag-pw',
  },
  {
    id: 'flag-py',
    shortcode: 'flag-py',
  },
  {
    id: 'flag-qa',
    shortcode: 'flag-qa',
  },
  {
    id: 'flag-re',
    shortcode: 'flag-re',
  },
  {
    id: 'flag-ro',
    shortcode: 'flag-ro',
  },
  {
    id: 'flag-rs',
    shortcode: 'flag-rs',
  },
  {
    id: 'flag-rw',
    shortcode: 'flag-rw',
  },
  {
    id: 'flag-sa',
    shortcode: 'flag-sa',
  },
  {
    id: 'flag-sb',
    shortcode: 'flag-sb',
  },
  {
    id: 'flag-sc',
    shortcode: 'flag-sc',
  },
  {
    id: 'flag-scotland',
    shortcode: 'flag-scotland',
  },
  {
    id: 'flag-sd',
    shortcode: 'flag-sd',
  },
  {
    id: 'flag-se',
    shortcode: 'flag-se',
  },
  {
    id: 'flag-sg',
    shortcode: 'flag-sg',
  },
  {
    id: 'flag-sh',
    shortcode: 'flag-sh',
  },
  {
    id: 'flag-si',
    shortcode: 'flag-si',
  },
  {
    id: 'flag-sj',
    shortcode: 'flag-sj',
  },
  {
    id: 'flag-sk',
    shortcode: 'flag-sk',
  },
  {
    id: 'flag-sl',
    shortcode: 'flag-sl',
  },
  {
    id: 'flag-sm',
    shortcode: 'flag-sm',
  },
  {
    id: 'flag-sn',
    shortcode: 'flag-sn',
  },
  {
    id: 'flag-so',
    shortcode: 'flag-so',
  },
  {
    id: 'flag-sr',
    shortcode: 'flag-sr',
  },
  {
    id: 'flag-ss',
    shortcode: 'flag-ss',
  },
  {
    id: 'flag-st',
    shortcode: 'flag-st',
  },
  {
    id: 'flag-sv',
    shortcode: 'flag-sv',
  },
  {
    id: 'flag-sx',
    shortcode: 'flag-sx',
  },
  {
    id: 'flag-sy',
    shortcode: 'flag-sy',
  },
  {
    id: 'flag-sz',
    shortcode: 'flag-sz',
  },
  {
    id: 'flag-ta',
    shortcode: 'flag-ta',
  },
  {
    id: 'flag-tc',
    shortcode: 'flag-tc',
  },
  {
    id: 'flag-td',
    shortcode: 'flag-td',
  },
  {
    id: 'flag-tf',
    shortcode: 'flag-tf',
  },
  {
    id: 'flag-tg',
    shortcode: 'flag-tg',
  },
  {
    id: 'flag-th',
    shortcode: 'flag-th',
  },
  {
    id: 'flag-tj',
    shortcode: 'flag-tj',
  },
  {
    id: 'flag-tk',
    shortcode: 'flag-tk',
  },
  {
    id: 'flag-tl',
    shortcode: 'flag-tl',
  },
  {
    id: 'flag-tm',
    shortcode: 'flag-tm',
  },
  {
    id: 'flag-tn',
    shortcode: 'flag-tn',
  },
  {
    id: 'flag-to',
    shortcode: 'flag-to',
  },
  {
    id: 'flag-tr',
    shortcode: 'flag-tr',
  },
  {
    id: 'flag-tt',
    shortcode: 'flag-tt',
  },
  {
    id: 'flag-tv',
    shortcode: 'flag-tv',
  },
  {
    id: 'flag-tw',
    shortcode: 'flag-tw',
  },
  {
    id: 'flag-tz',
    shortcode: 'flag-tz',
  },
  {
    id: 'flag-ua',
    shortcode: 'flag-ua',
  },
  {
    id: 'flag-ug',
    shortcode: 'flag-ug',
  },
  {
    id: 'flag-um',
    shortcode: 'flag-um',
  },
  {
    id: 'flag-uy',
    shortcode: 'flag-uy',
  },
  {
    id: 'flag-uz',
    shortcode: 'flag-uz',
  },
  {
    id: 'flag-va',
    shortcode: 'flag-va',
  },
  {
    id: 'flag-vc',
    shortcode: 'flag-vc',
  },
  {
    id: 'flag-ve',
    shortcode: 'flag-ve',
  },
  {
    id: 'flag-vg',
    shortcode: 'flag-vg',
  },
  {
    id: 'flag-vi',
    shortcode: 'flag-vi',
  },
  {
    id: 'flag-vn',
    shortcode: 'flag-vn',
  },
  {
    id: 'flag-vu',
    shortcode: 'flag-vu',
  },
  {
    id: 'flag-wales',
    shortcode: 'flag-wales',
  },
  {
    id: 'flag-wf',
    shortcode: 'flag-wf',
  },
  {
    id: 'flag-ws',
    shortcode: 'flag-ws',
  },
  {
    id: 'flag-xk',
    shortcode: 'flag-xk',
  },
  {
    id: 'flag-ye',
    shortcode: 'flag-ye',
  },
  {
    id: 'flag-yt',
    shortcode: 'flag-yt',
  },
  {
    id: 'flag-za',
    shortcode: 'flag-za',
  },
  {
    id: 'flag-zm',
    shortcode: 'flag-zm',
  },
  {
    id: 'flag-zw',
    shortcode: 'flag-zw',
  },
  {
    id: 'fr',
    shortcode: 'fr',
  },
  {
    id: 'fr',
    shortcode: 'flag-fr',
  },
  {
    id: 'gb',
    shortcode: 'gb',
  },
  {
    id: 'gb',
    shortcode: 'uk',
  },
  {
    id: 'gb',
    shortcode: 'flag-gb',
  },
  {
    id: 'gb',
    shortcode: 'flag-uk',
  },
  {
    id: 'it',
    shortcode: 'it',
  },
  {
    id: 'it',
    shortcode: 'flag-it',
  },
  {
    id: 'jp',
    shortcode: 'jp',
  },
  {
    id: 'jp',
    shortcode: 'flag-jp',
  },
  {
    id: 'kr',
    shortcode: 'kr',
  },
  {
    id: 'kr',
    shortcode: 'flag-kr',
  },
  {
    id: 'rainbow-flag',
    shortcode: 'rainbow-flag',
  },
  {
    id: 'ru',
    shortcode: 'ru',
  },
  {
    id: 'ru',
    shortcode: 'flag-ru',
  },
  {
    id: 'triangular_flag_on_post',
    shortcode: 'triangular_flag_on_post',
  },
  {
    id: 'us',
    shortcode: 'us',
  },
  {
    id: 'us',
    shortcode: 'flag-us',
  },
  {
    id: 'waving_black_flag',
    shortcode: 'waving_black_flag',
  },
  {
    id: 'waving_white_flag',
    shortcode: 'waving_white_flag',
  },
]
