import React, { Component } from 'react';
import Typing from 'react-typing-animation';
import { Parallax } from 'react-scroll-parallax';

import './styles.scss';


class Hero extends Component {
  render() {
    const emojis = [
      {
        name: 'angry',
        hidden: false,
        y1: '-40px',
        y2: '-20px',
      },
      {
        name: 'kiss',
        hidden: true,
        y1: '-20px',
        y2: '200px',
      },
      {
        name: 'laugh',
        hidden: true,
        y1: '-20px',
        y2: '20px',
      },
      {
        name: 'ponder',
        hidden: false,
        y1: '-20px',
        y2: '80px',
      },
      {
        name: 'poop',
        hidden: false,
        y1: '-40px',
        y2: '40px',
      },
      {
        name: 'sunglasses',
        hidden: false,
        y1: '-20px',
        y2: '10px',
      },
    ]

    return (
      <section className="hero is-fullheight">
        <div className="hero-head">
        </div>

        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title is-spaced">
              Shortmoji
            </h1>
            <h2 className="subtitle">
              Turn <b>:heart_eyes:</b> into <span role="img" aria-label="Heart Eyes">😍</span> on Mac and iOS.
            </h2>
          </div>
        </div>

        <div className="hero-foot">
          <section className="section has-padding-bottom-medium">
            <div className="container">
              <Typing
                className="columns is-mobile is-vcentered is-centered is-size-5"
                speed={100}
                startDelay={500}
                loop={true}
              >
                <span>:arrow_down:</span>
                <Typing.Reset count={1} delay={0} />
                <span className="is-family-sans-serif" role="img" aria-label="Arrow Down">⬇️</span>
                <Typing.Reset delay={2500} />
              </Typing>
            </div>
          </section>
        </div>

        {emojis.map((emoji, index) =>
          <Parallax className={`parallax-emoji ${emoji.name} ${emoji.hidden ? 'is-hidden-touch' : ''}`} y={[emoji.y1, emoji.y2]} key={index}>
            <img src={`/images/emojis/${emoji.name}.svg`} alt={`${emoji.name} emoji`} />
          </Parallax>
        )}
      </section>
    );
  }
}

export default Hero;
