import React, { Component } from 'react';
import { Emoji } from 'emoji-mart';

import './styles.scss';


class EmojiItem extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      id: this.props.id,  // used to mark when a emoji gets changed (i.e. the base changes and the shortcode should update)
      skin: this.props.skin,  // used to mark when a emoji gets changed (i.e. the base changes and the shortcode should update)
      shortcode: this.props.shortcode,  // passed down only once, updated in this component to avoid re-renders of EditEmojis
    };

    this.updateEmoji = this.updateEmoji.bind(this);
    this.deleteEmoji = this.deleteEmoji.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.id !== state.id || props.skin !== state.skin) {
      return {
        id: props.id,
        skin: props.skin,
        shortcode: props.shortcode,
      }
    }
    return null;
  }

  updateEmoji(event) {
    this.setState({shortcode: event.target.value});
    this.props.handleEmojiShortcodeChange(this.props.index, event.target.value);
  }

  deleteEmoji() {
    this.props.handleEmojiShortcodeChange(this.props.index, null);
  }

  render() {
    return (
      <div className="EmojiItem field has-addons">
        <p className="control">
          <span className="button is-static">
            <Emoji emoji={this.props.id} skin={this.props.skin || 1} size={24} />
          </span>
        </p>
        <p className="control is-expanded">
          <input
            className="input"
            type="text"
            placeholder="shortcode"
            value={this.state.shortcode}
            onChange={this.updateEmoji}
          />
        </p>
        <p className="control">
          <button className="button is-danger" onClick={this.deleteEmoji}>
            <span className="icon is-size-7">
              <i className="fas fa-trash-alt"></i>
            </span>
          </button>
        </p>
      </div>
    );
  }
}

export default EmojiItem;
