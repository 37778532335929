import React, { Component } from 'react';

import './styles.scss';


class Installation extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isDownloading: false,
    };

    this.download = this.download.bind(this);
  }

  download() {
    this.setState({isDownloading: true}, () => {
      setTimeout(() => {
        this.props.handleDownload();
        this.setState({isDownloading: false});
      }, 1);
    });
  }

  render() {
    return (
      <div className="card">
        <header id="download-header" className="card-header has-text-white" data-action="collapse" data-target="download-card">
          <h3 className="card-header-title has-text-white"><span role="img" aria-label="Download">📥</span>&nbsp;&nbsp;Download &amp; Install</h3>
          <p className="card-header-icon" aria-label="more options">
            <span className="icon">
              <i className="fas fa-angle-down" aria-hidden="true"></i>
              <i className="fas fa-angle-up" aria-hidden="true"></i>
            </span>
          </p>
        </header>
        <div id="download-card" className="is-collapsible" data-parent="installation-accordion">
          <div className="card-content">
            {/*Download*/}
            <div className="content has-padding-top-small has has-padding-bottom-small">
              <h4>Download your shortmojis</h4>
              <div className="columns">
                <div className="column">
                  <p>After you've customized your shortmojis, download them by clicking on the button.</p>
                </div>
                <div className="column has-text-centered">
                  <button className={`button is-info is-large is-centered ${this.state.isDownloading ? 'is-loading' : ''}`} onClick={this.download}>Download</button>
                  <p className="is-size-6 has-text-info">{this.props.emojiCount.toLocaleString()} shortmojis with <code>{this.props.beforeShortcode}shortcode{this.props.afterShortcode}</code> format</p>
                </div>
              </div>
            </div>

            {/*Install*/}
            <div className="content has-padding-top-small has has-padding-bottom-small">
              <h4>Install your shortmojis</h4>
              <div className="columns">
                <div className="column">
                  <ul>
                    <li>Open "System Preferences" on your Mac</li>
                    <li>Click "Keyboard"</li>
                    <li>Select the "Text" menu item</li>
                    <li>Drag the <code>Shortmojis.plist</code> file you downloaded into the "Replace/With" area</li>
                  </ul>
                </div>
                <div className="column has-text-centered">
                  <img
                    src="/images/installation.gif"
                    alt="Installation instructions"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Installation;
