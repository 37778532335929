import React, { Component } from 'react';

import './styles.scss';


class SetupBase extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      processingSkin: 0,
      processingBase: '',
    };
  }

  changeSkin(skin) {
    this.setState({processingSkin: skin}, () => {
      setTimeout(() => {
        this.props.handleSkinChange(skin);
        this.setState({processingSkin: 0});
      }, 1);
    });
  }

  changeBase(base) {
    this.setState({processingBase: base}, () => {
      setTimeout(() => {
        this.props.handleEmojiBaseChange(base);
        this.setState({processingBase: ''});
      }, 1);
    });
  }

  render() {
    const skinTones = [...Array(7).keys()].slice(1); // 1 to 6
    const bases = [
      {
        key: 'default',
        name: 'Default',
        tooltip: 'The recommended emojis and shortcodes',
        count: 20,
      },
      {
        key: 'none',
        name: 'None',
        tooltip: 'Start completely from scratch',
        count: 0,
      },
      {
        key: 'slack',
        name: 'Slack',
        tooltip: 'Use Slack\'s default emojis and shortcodes',
        count: 1559,
      },
      {
        key: 'extended',
        name: 'Extended',
        tooltip: 'Includes more emojis and synonyms for shortcodes',
        count: 1616,
      },
    ]

    return (
      <div className="card">
        <header id="customize-header" className="card-header has-text-white" data-action="collapse" data-target="customize-card">
          <h3 className="card-header-title has-text-white"><span role="img" aria-label="Setup">🛠</span>&nbsp;&nbsp;Choose Defaults</h3>
          <p className="card-header-icon" aria-label="more options">
            <span className="icon">
              <i className="fas fa-angle-down" aria-hidden="true"></i>
              <i className="fas fa-angle-up" aria-hidden="true"></i>
            </span>
          </p>
        </header>
        <div id="customize-card" className="is-collapsible is-active" data-parent="installation-accordion">
          <div className="card-content">

            {/*Template*/}
            <div className="content has-padding-top-small has has-padding-bottom-small">
              <h4>Set your autocorrect template</h4>
              <div className="columns">
                <div className="column">
                  <p>Choose what template you'd like to use for your shortmojis. The default is <code>:</code> before and after text, but you can set it to anything you'd like.</p>
                </div>
                <div className="column has-text-centered">
                  <div className="field has-addons has-margin-bottom-none">
                    <p className="control has-margin-bottom-none">
                      <input
                        className="input is-size-5 has-text-right"
                        type="text"
                        placeholder="before"
                        value={this.props.beforeShortcode}
                        onChange={this.props.handleBeforeShortcodeChange}
                      />
                    </p>
                    <p className="control has-margin-bottom-none">
                      <span className="button is-size-5 is-static"><code>heart</code></span>
                    </p>
                    <p className="control has-margin-bottom-none">
                      <input
                        className="input is-size-5"
                        type="text"
                        placeholder="after"
                        value={this.props.afterShortcode}
                        onChange={this.props.handleAfterShortcodeChange}
                      />
                    </p>
                  </div>
                  <p className="is-size-6"><b>{this.props.beforeShortcode}heart{this.props.afterShortcode}</b> will autocorrect to <span role="img" aria-label="Heart">❤️</span></p>
                </div>
              </div>
            </div>

            {/*Base*/}
            <div className="content has-padding-top-small has has-padding-bottom-small">
              <h4>Select a skin tone and starter set</h4>
              <div className="columns">
                <div className="column">
                  <p>To get started, you can choose from several different base emoji sets. First, choose a default skin tone and then select a base emoji set. Or, start from scratch entirely and build your own.</p>
                </div>
                <div className="column has-text-centered">
                  <div className="tabs is-toggle is-fullwidth">
                    <ul className="has-margin-top-none has-margin-left-none">
                      {skinTones.map((skin) =>
                        <li
                          key={skin}
                          className={this.props.skin === skin ? 'is-active' : ''}
                        >
                          {/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
                          <a
                            className={`button is-small has-background-skin${skin} ${this.state.processingSkin === skin ? 'is-loading' : ''}`}
                            onClick={() => this.changeSkin(skin)}
                          >
                            <span className={`icon is-small has-text-white ${this.props.skin === skin ? '' : 'is-invisible'}`}><i className="fas fa-check-circle" aria-hidden="true"></i></span>
                          </a>
                          {/* eslint-enable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="field is-grouped is-grouped-centered">
                    {bases.map((base, index) =>
                      <p className="control" key={index}>
                        <button
                          className={`button is-size-5 tooltip is-tooltip-multiline ${this.props.base === base.key ? 'is-active is-primary' : ''} ${this.state.processingBase === base.key ? 'is-loading' : ''}`}
                          onClick={() => this.changeBase(base.key)}
                          data-tooltip={`${base.tooltip} (${base.count.toLocaleString()} shortmojis)`}>
                          {base.name}
                        </button>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SetupBase;
