import React, { Component } from 'react';
import { NimblePicker as Picker } from 'emoji-mart';
import appleData from 'emoji-mart/data/apple.json';

import 'emoji-mart/css/emoji-mart.css';
import './styles.scss';


class EmojiPicker extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return this.props.skin !== nextProps.skin || this.props.active !== nextProps.active;
  }

  render() {
    return (
      <div className={`modal ${this.props.active && "is-active"}`}>
        <div className="modal-background" onClick={() => this.props.setPickerVisibility(false)}></div>
        <div className="modal-content">
          <Picker
            set="apple"
            data={appleData}
            sheetSize={32}
            emojiSize={28}
            emoji=""
            title=""
            skin={this.props.skin}
            showPreview={false}
            showSkinTones={true}
            onClick={this.props.action}
            style={{width: '100%'}}
          />
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={() => this.props.setPickerVisibility(false)}></button>
      </div>
    );
  }
}

export default EmojiPicker;
