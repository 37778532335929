import React, { Component } from 'react';
import Typing from 'react-typing-animation';

import './styles.scss';


class Footer extends Component {
  addAuthorLink() {
    console.log('HERE');
  }

  render() {
    return (
      <footer className="footer has-background-primary has-text-white has-text-centered">
        <div className="content">
          <Typing
            className="typing columns is-mobile is-vcentered is-centered is-size-5"
            speed={100}
            startDelay={500}
            loop={true}
          >
            <span>Made with&nbsp;</span>
            <span>:love:</span>
            <Typing.Reset count={1} delay={0} />
            <span className="is-family-sans-serif" role="img" aria-label="Love">❤️</span>
            <span>&nbsp;by&nbsp;</span>
            <span><a href="http://alexsands.com" target="_blank" rel="noopener noreferrer">Alex</a></span>
            <span>.</span>
            <Typing.Reset delay={10000} />
          </Typing>
          <span className="is-size-7">Copyright &copy; {new Date().getFullYear()}</span>
        </div>
      </footer>
    );
  }
}

export default Footer;
